import React from 'react'
import { useSelector } from 'react-redux'
import { Trans } from 'react-i18next'
import { Link } from 'gatsby'
import PATHS from 'src/utils/paths'
import { InfoMessage } from 'de-suite-me-ui'

import {
  getIsAuthUserFetched,
  hasUserFirstNameAnaLastNameSaved
} from 'src/features/auth/duck/selectors';

import AlertIcon from 'src/components/icons/Alert'

export const MissingDataInfoMessage = (props) => {
  const hasFirstNameAnaLastNameSaved = useSelector(hasUserFirstNameAnaLastNameSaved)
  const isAuthUserFetched = useSelector(getIsAuthUserFetched)

  return isAuthUserFetched && !hasFirstNameAnaLastNameSaved && (
    <InfoMessage type='warning' startIcon={<AlertIcon />}>
      <Trans i18nKey='infoMessages.missingProfileData'>
        0 <Link to={PATHS.workspaceMyAccount}>1</Link> 2
      </Trans>
    </InfoMessage>
  )
}

export default MissingDataInfoMessage
