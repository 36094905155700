import { API_STATES } from 'src/utils/api'
import {
  propEq,
  propOr,
  pipe,
  sortBy,
  prop,
  reverse,
  pathOr,
  filter,
  length,
  allPass,
  isEmpty,
  not,
  ifElse,
  always,
  head,
  any,
  map,
  path
} from 'ramda'
import { createSelector } from 'reselect'
import { transformToArray } from 'src/utils/redux'
import { getAuthUser } from 'src/features/auth/duck/selectors'
import { dateIsNotFromCurrentMonth } from 'src/utils/date'

export const selectAppreciations = state => state.appreciations

export const isAppreciationsLoading = createSelector(
  selectAppreciations,
  propEq('state', API_STATES.IN_PROGRESS)
)

export const getAppreciations = createSelector(
  selectAppreciations,
  pipe(
    propOr({}, 'appreciations'),
    transformToArray,
    sortBy(prop('createdAt')),
    reverse
  )
)

export const getPagination = createSelector(
  selectAppreciations,
  propOr({}, 'metaAll')
)

export const getReactionCountByAppreciationIdAndReactionContent = createSelector(
  selectAppreciations,
  (state, appreciationId) => appreciationId,
  (state, appreciationId, reactionContent) => reactionContent,
  (appreciations, appreciationId, reactionContent) => {
    return pipe(
      pathOr([], ['appreciations', appreciationId, 'reactions']),
      filter(propEq('content', reactionContent)),
      length
    )(appreciations)
  }
)

export const isReactionSelectedByUser = createSelector(
  getAuthUser,
  selectAppreciations,
  (state, appreciationId) => appreciationId,
  (state, appreciationId, reactionContent) => reactionContent,
  (authUser, appreciations, appreciationId, reactionContent) => {
    return pipe(
      pathOr([], ['appreciations', appreciationId, 'reactions']),
      filter(
        allPass([
          propEq('content', reactionContent),
          propEq('giverId', authUser.id)
        ])
      ),
      isEmpty,
      not
    )(appreciations)
  }
)

export const getIsAppreciationFromPreviousMonth = createSelector(
  selectAppreciations,
  (state, appreciationId) => appreciationId,
  (appreciations, appreciationId) => {
    const appreciationDate = pathOr('', [
      'appreciations',
      appreciationId,
      'createdAt'
    ])(appreciations)

    return dateIsNotFromCurrentMonth(appreciationDate)
  }
)

export const getSelectedReactionId = createSelector(
  getAuthUser,
  selectAppreciations,
  (state, appreciationId) => appreciationId,
  (state, appreciationId, reactionContent) => reactionContent,
  (authUser, appreciations, appreciationId, reactionContent) => {
    return pipe(
      pathOr([], ['appreciations', appreciationId, 'reactions']),
      filter(
        allPass([
          propEq('content', reactionContent),
          propEq('giverId', authUser.id)
        ])
      ),
      ifElse(isEmpty, always(''), pipe(head, propOr('', 'id')))
    )(appreciations)
  }
)

export const getAuthUserGivenAppreciations = createSelector(
  getAppreciations,
  getAuthUser,
  (appreciations, authUser) =>
    filter(propEq('giverId', authUser.id))(appreciations)
)

export const getAuthUserReceivedAppreciations = createSelector(
  getAppreciations,
  getAuthUser,
  (appreciations, authUser) =>
    filter(pipe(propOr([], 'receivers'), any(propEq('id', authUser.id))))(
      appreciations
    )
)

export const getGivenPagination = createSelector(
  selectAppreciations,
  propOr({}, 'metaGiven')
)

export const getReceivedPagination = createSelector(
  selectAppreciations,
  propOr({}, 'metaReceived')
)

export const getAppreciationsGivenCount = createSelector(
  selectAppreciations,
  pathOr(0, ['userAppreciationsCounters', 'appreciationsGiven'])
)

export const getAppreciationsReceivedCount = createSelector(
  selectAppreciations,
  pathOr(0, ['userAppreciationsCounters', 'appreciationsReceived'])
)

export const getReactionGiverNamesByAppreciationIdAndReactionContent = createSelector(
  selectAppreciations,
  (state, appreciationId) => appreciationId,
  (state, appreciationId, reactionContent) => reactionContent,
  (appreciations, appreciationId, reactionContent) => {
    return pipe(
      pathOr([], ['appreciations', appreciationId, 'reactions']),
      filter(propEq('content', reactionContent)),
      map(path(['giver', 'firstName']))
    )(appreciations)
  }
)
