import { isNotNilOrEmpty } from './ramda'

export const pointOptions = minPoints => {
  const base = minPoints === 0 ? 50 : minPoints

  return [
    {
      value: base,
      label: base
    },
    {
      value: base + base / 2,
      label: base + base / 2
    },
    {
      value: base + (base / 2) * 2,
      label: base + (base / 2) * 2
    },
    {
      value: base + (base / 2) * 3,
      label: base + (base / 2) * 3
    },
    {
      value: base + (base / 2) * 4,
      label: base + (base / 2) * 4
    }
  ]
}

export const wordOfPraiseTranslationKeys = [
  'appreciations.wordOfPraise.perfect',
  'appreciations.wordOfPraise.fantastic',
  'appreciations.wordOfPraise.great',
  'appreciations.wordOfPraise.unbelievable',
  'appreciations.wordOfPraise.announcement',
  'appreciations.wordOfPraise.wow',
  'appreciations.wordOfPraise.goodNews',
  'appreciations.wordOfPraise.respect',
  'appreciations.wordOfPraise.congratulations',
  'appreciations.wordOfPraise.excellently',
  'appreciations.wordOfPraise.splendidly',
  'appreciations.wordOfPraise.wellDone',
  'appreciations.wordOfPraise.wonderfully',
  'appreciations.wordOfPraise.magnificently',
  'appreciations.wordOfPraise.perfectly'
]

export const getRandomElementFromArray = (array, randomDigit) => {
  const baseRandomDigit = randomDigit ? randomDigit / 100 : Math.random()
  return array[Math.floor(baseRandomDigit * array.length)]
}

export const getPointsName = (t, count, pluralName) => {
  switch (true) {
    case count === 2 && isNotNilOrEmpty(pluralName):
      return t('dashboardPage.appreciationCTAEmpty')
    case isNotNilOrEmpty(pluralName):
      return pluralName
    default:
      return t('pointsName.points', { count })
  }
}

export const appreciationFormFields = {
  points: 'points',
  receivers: 'receivers',
  content: 'content',
  coreValues: 'coreValues'
}
