import React from 'react'
import { useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getPointsName } from 'src/utils/appreciations'
import {
  getHasPointSystem, getOrganisationPointsPluralName
} from 'src/features/organisation/duck/selectors'
import { getFirstName, getPointsLeft } from 'src/features/auth/duck/selectors'

import { Emoji } from 'emoji-mart'
import DeviceDetector from 'src/components/DeviceDetector'
import { isNotNilOrEmpty } from 'src/utils/ramda'

export const CreateAppreciationWelcomeText = props => {
  // REDUX
  const hasPointSystem = useSelector(getHasPointSystem)
  const pointsLeft = useSelector(getPointsLeft)
  const firstName = useSelector(getFirstName)
  const pointsPluralName = useSelector(getOrganisationPointsPluralName)

  const { t } = useTranslation()

  const pointsName = getPointsName(t, pointsLeft, pointsPluralName)

  const pointSystemContent = () => (
    <div className='welcome-text-section__container'>
      {isNotNilOrEmpty(firstName) && <span className='welcome-text-section__name web-inline'>{firstName},&nbsp;</span>}
      {/* Trans component that supports the following schema */}
      {/* <0>First part of text</0><1><0>variable inside html tag</0></1><2>variable</2> */}
      <div className={`web-inline ${isNotNilOrEmpty(firstName) ? '' : 'welcome-text-section__text-capitalize'}`}>
        <Trans i18nKey='appreciations.pointsSummary'>
          0
          <span className='welcome-text-section__points-left'>
          {{ pointsLeft: ' ' + pointsLeft + ' ' }}
        </span>
          {{ pointsName }}
        </Trans>
      </div>
      <div className='mobile-inline welcome-text-section__text-capitalize'>
        <Trans i18nKey='appreciations.pointsSummaryShort'>
          0
          <span className='welcome-text-section__points-left'>
          {{ pointsLeft: ' ' + pointsLeft + ' ' }}
        </span>
          {{ pointsName }}
        </Trans>
      </div>
      &nbsp;
      <div className='welcome-text-section__emoji'>
        <Emoji
          set='apple'
          emoji='relaxed'
          size={20}
          fallback={emoji => {
            return emoji ? `:${emoji.short_names[0]}:` : 'relaxed'
          }}
        />
      </div>
    </div>
  )

  const nonPointSystemContent = () => (
    <div className='welcome-text-section__container'>
      <span className='welcome-text-section__name web'>{firstName},&nbsp;</span>
      <span>{t('appreciations.nonPointWelcome')}&nbsp;</span>
      <div className='welcome-text-section__emoji'>
        <Emoji
          set='apple'
          emoji='relaxed'
          size={20}
          fallback={emoji => {
            return emoji ? `:${emoji.short_names[0]}:` : 'relaxed'
          }}
        />
      </div>
    </div>
  )

  return (
    <WelcomeTextContainer>
      <DeviceDetector>
        {hasPointSystem ? pointSystemContent() : nonPointSystemContent()}
      </DeviceDetector>
    </WelcomeTextContainer>
  )
}

export default CreateAppreciationWelcomeText

const WelcomeTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 26px;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    padding-bottom: 10px;
  }

  .welcome-text-section__emoji {
    display: inline-block;
    transform: translateY(25%);
  }

  .welcome-text-section__container {
    font-size: 12px;
    text-transform: lowercase;
    color: ${props => props.theme.palette.common.gray};
  }
  .welcome-text-section__name {
    letter-spacing: -0.5px;
    font-size: 19px;
    text-transform: capitalize;
    font-weight: 600;
    color: ${props => props.theme.palette.text.main};
  }

  .welcome-text-section__points-left {
    color: ${props => props.theme.palette.text.main};
    font-size: 22px;
    letter-spacing: -0.5px;
    font-weight: 600;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      color: ${props => props.theme.palette.primary.main};
    }
  }
  
  .welcome-text-section__text-capitalize::first-letter {
    text-transform: capitalize;
  }
`
