import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { isNotNilOrEmpty } from 'src/utils/ramda'

import { createAppreciationRoutine } from 'src/features/appreciations/duck/actions'
import { getHasPointSystem } from 'src/features/organisation/duck/selectors'

import {
  validateNonPointAppreciationValues,
  validatePointAppreciationValues
} from 'src/features/appreciations/duck/schema'
import { primaryBlurredBoxShadow } from 'src/components/AnimationKeyframes'
import EmojiPicker from 'src/components/EmojiPicker'
import Form from 'src/components/atoms/Form'
import CreateAppreciationInput from 'src/features/appreciations/components/CreateAppreciationInput/CreateAppreciationInput'
import EmojiButton from 'src/components/EmojiButton'
import CreateAppreciationPointsSummary from 'src/features/appreciations/components/CreateAppreciationWelcomeText'
import CreateAppreciationButton from 'src/features/appreciations/components/CreateAppreciationButton'
import CreateAppreciationEncourageText from 'src/features/appreciations/components/CreateAppreciationEncourageText'
import { fetchCoreValuesRoutine } from 'src/features/coreValues/duck/actions'
import SelectedCoreValues from 'src/features/appreciations/components/CreateAppreciationInput/components/SelectedCoreValues'

const emptyValues = {
  appreciation: {}
}

export const CreateAppreciation = props => {
  // REDUX
  const dispatch = useDispatch()
  const hasPointSystem = useSelector(getHasPointSystem)
  const createAppreciation = useCallback(
    payload => dispatch(createAppreciationRoutine(payload)),
    [dispatch]
  )
  const fetchCoreValues = useCallback(
    () => dispatch(fetchCoreValuesRoutine()),
    [dispatch]
  )

  // STATE
  const [focusedFieldName, setFocusedFieldName] = useState('')
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const [selectedEmojiObj, setSelectedEmojiObj] = useState({
    emoji: '',
    timestamp: ''
  })
  const [isEmojiOpen, setEmojiVisibility] = useState(false)
  const [shouldReset, setShouldReset] = useState(false)

  useEffect(() => {
    hasPointSystem
      ? validatePointAppreciationValues(values, _validateSchema)
      : validateNonPointAppreciationValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    fetchCoreValues()
  }, [])

  const handleOnChange = (name, value) => {
    setValues({ ...values, [name]: value })
    setShouldReset(false)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    createAppreciation(values.appreciation)
    setShouldReset(true)
    setValues(emptyValues)
  }

  const handleEmojiClick = emojiObject =>
    // timestamp is added to track each emoji selection
    setSelectedEmojiObj({ emoji: emojiObject.native, timestamp: new Date() })
  const toggleEmojiVisibility = () =>
    setEmojiVisibility(prevState => !prevState)

  return (
    <AppreciationContainer isFocused={isNotNilOrEmpty(focusedFieldName)}>
      <Form onSubmit={handleSubmit}>
        <CreateAppreciationPointsSummary />
        <CreateAppreciationContainer>
          <CreateAppreciationInput
            focusedFieldName={focusedFieldName}
            setFocusedFieldName={setFocusedFieldName}
            name='appreciation'
            onChange={handleOnChange}
            reset={shouldReset}
            selectedEmojiObj={selectedEmojiObj}
            appreciation={values}
          />
          <SelectedCoreValues values={values} />
          <BottomSectionContainer>
            <CreateAppreciationEncourageText
              focusedFieldName={focusedFieldName}
            />
            <div className='create-appreciation__bottom-section-container-buttons'>
              <EmojiButton
                open={isEmojiOpen}
                className='create-appreciation__bottom-section-container-buttons--emoji'
                onClick={toggleEmojiVisibility}
              />
              <CreateAppreciationButton
                disabled={!valid}
                onClick={handleSubmit}
                values={values}
              />
            </div>
          </BottomSectionContainer>
          <EmojiPicker isOpen={isEmojiOpen} onChange={handleEmojiClick} />
        </CreateAppreciationContainer>
      </Form>
    </AppreciationContainer>
  )
}

export default CreateAppreciation

const BottomSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  .create-appreciation__bottom-section-container-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${props => props.theme.breakpointsMedia.mobile} {
      margin-top: 0;
    }
  }

  .create-appreciation__bottom-section-container-buttons--emoji {
    margin-right: 10px;
  }

  ${props => props.theme.breakpointsMedia.mobile} {
    margin-top: 10px;
  }
`

const CreateAppreciationContainer = styled.div`
  box-sizing: border-box;
`

const AppreciationContainer = styled.div`
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  padding: 26px;
  background-color: ${props => props.theme.palette.common.white};
  border-radius: 4px;
  border: 1px solid ${props => props.theme.palette.common.mercury};
  box-shadow: ${props => props.theme.shadows.main};
  animation: ${props =>
    props.isFocused
      ? 'none'
      : css`
          ${primaryBlurredBoxShadow} 6s ease-in-out infinite
        `};

  ${props => props.theme.breakpointsMedia.mobile} {
    padding: 15px;
  }
`
