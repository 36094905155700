import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { currentYearMonth } from 'src/utils/date'
import { map } from 'ramda'
import { copyElementToClipboardById } from 'src/utils/clipboard'
import { SEVERITY } from 'src/utils/toast'
import { isNotNilOrEmpty } from 'src/utils/ramda'

import { getPointsReportRoutine } from 'src/features/reports/duck/actions'
import { showToastRoutine } from 'src/features/toast/duck/actions'
import {
  getPointsReportTotalPointsGiven,
  getPointsReportUsersAsArray
} from 'src/features/reports/duck/selectors'

import {
  Table,
  TableBody,
  TableCell,
  TableHeaderCell,
  TableHead,
  TableRow,
  TableFooter
} from 'src/components/atoms/Table/Table'
import SelectReportMonth from 'src/features/reports/components/SelectReportMonth'
import Button from 'src/components/atoms/Button'
import FileCopyIcon from 'src/components/icons/FileCopy'
import Tooltip from 'src/components/atoms/Tooltip'
import EmptyState from 'src/components/EmptyState'

export const UserAppreciationsPointsReport = props => {
  //  REDUX
  const dispatch = useDispatch()
  const pointsReportData = useSelector(getPointsReportUsersAsArray)
  const pointsReportTotalPointsGiven = useSelector(
    getPointsReportTotalPointsGiven
  )
  const pointsReportTotalPointsLeft = pointsReportData.reduce(
    (acc, entity) => acc + entity.pointsLeft,
    0
  )
  const getPointsReport = useCallback(
    payload => dispatch(getPointsReportRoutine(payload)),
    [dispatch]
  )
  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const { t } = useTranslation()

  const [selectedDate, setSelectedDate] = useState(currentYearMonth)
  const isSelectedCurrentMonth = selectedDate === currentYearMonth

  useEffect(() => {
    getPointsReport(currentYearMonth)
  }, [])

  const handleChangeDate = values => {
    setSelectedDate(values)
    getPointsReport(values)
  }

  const dataRow = entity => (
    <TableRow key={`points-report-${entity.firstName}-${entity.lastName}`}>
      <TableCell>{entity.firstName}</TableCell>
      <TableCell>{entity.lastName}</TableCell>
      <TableCell>{entity.totalReceivedPoints}</TableCell>
      {isSelectedCurrentMonth && <TableCell>{entity.pointsLeft}</TableCell>}
      <TableCell>{entity.pointRatio.toFixed(2)}</TableCell>
    </TableRow>
  )

  const TableEmptyState = (
    <TableRow>
      <TableCell colSpan={4}>
        <EmptyStateContainer>
          <EmptyState text={t('manageUsersPage.manageUsers.empty')} />
        </EmptyStateContainer>
      </TableCell>
    </TableRow>
  )

  const renderRows = map(dataRow)(pointsReportData)
  const tableId = 'UserAppreciationsPointsReport'

  const handleCopyTable = e => {
    e.preventDefault()
    copyElementToClipboardById(tableId)
    showToast({
      key: 'toast.copied',
      severity: SEVERITY.success
    })
  }

  return (
    <>
      <ManageTableHeader>
        <Tooltip title={t('reportsPage.copy')}>
          <ButtonContainer>
            <Button onClick={handleCopyTable} variant='outlined'>
              <FileCopyIcon />
            </Button>
          </ButtonContainer>
        </Tooltip>
        <SelectReportMonth
          value={currentYearMonth}
          onChange={handleChangeDate}
        />
      </ManageTableHeader>
      <TableContainer>
        <Table id={tableId} aria-label='points table'>
          <TableHead>
            <TableRow>
              <TableHeaderCell>
                {t('reportsPage.pointsReportTableHeaders.firstName')}
              </TableHeaderCell>
              <TableHeaderCell>
                {t('reportsPage.pointsReportTableHeaders.lastName')}
              </TableHeaderCell>
              <TableHeaderCell>
                {t('reportsPage.pointsReportTableHeaders.receivedPoints')}
              </TableHeaderCell>
              {isSelectedCurrentMonth && (
                <TableHeaderCell>
                  {t('reportsPage.pointsReportTableHeaders.pointsLeft')}
                </TableHeaderCell>
              )}
              <TableHeaderCell>
                {t('reportsPage.pointsReportTableHeaders.pointRatio')}
              </TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isNotNilOrEmpty(pointsReportData) ? renderRows : TableEmptyState}
          </TableBody>
          {isNotNilOrEmpty(pointsReportData) && (
            <TableFooter>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell>{pointsReportTotalPointsGiven}</TableCell>
                {isSelectedCurrentMonth && <TableCell>{pointsReportTotalPointsLeft}</TableCell>}
                <TableCell>1</TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </>
  )
}

export default UserAppreciationsPointsReport

const ManageTableHeader = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ButtonContainer = styled.div`
  margin-right: 20px;
`

const EmptyStateContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TableContainer = styled.div`
  display: inline-block;
  width: 100%;
  overflow-x: auto;
`