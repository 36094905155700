import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import PATHS from 'src/utils/paths'
import { getUserPhotoOrAvatar } from 'src/utils/user'

import { logoutUserRoutine } from 'src/features/auth/duck/actions'
import {
  getAuthUser,
  getFirstName,
  getIsAdminUser,
  getJobFunction,
  getLastName
} from 'src/features/auth/duck/selectors'

import {
  SectionContainer,
  SectionTitleContainer,
  SectionTitle,
  SectionSubtitleContainer,
  SectionSubtitle
} from 'src/features/auth/components/AuthRightSideBar/styled'
import {
  floating1,
  floating2,
  floating3,
  floating4
} from 'src/components/AnimationKeyframes'
import PhotoFrame from 'src/assets/images/vectors/photoFrame.svg'
import MoreDropdownMenu from 'src/components/MoreDropdownMenu'
import AuthRightSideBarPhotoUpload from 'src/features/auth/components/AuthRightSideBar/AuthRightSideBarPhotoUpload'

export const AuthRightSideBarMyProfileSection = props => {
  // REDUX
  const dispatch = useDispatch()
  const isAdminUser = useSelector(getIsAdminUser)
  const firstName = useSelector(getFirstName)
  const lastName = useSelector(getLastName)
  const jobFunction = useSelector(getJobFunction)
  const authUser = useSelector(getAuthUser)
  const logoutUser = useCallback(() => dispatch(logoutUserRoutine()), [
    dispatch
  ])

  const { t } = useTranslation()

  const dropdownMenuItems = [
    {
      onClick: () => navigate(PATHS.workspaceMyAccount),
      name: t('authNavigation.myAccount')
    },
    {
      onClick: () => logoutUser(),
      name: t('authNavigation.logout')
    }
  ]

  const userImage = getUserPhotoOrAvatar(authUser)

  return (
    <SectionContainer>
      <SectionTitleContainer>
        <SectionTitle>{t('authSideBar.profile.title')}</SectionTitle>
        <MoreDropdownMenu data={dropdownMenuItems} />
      </SectionTitleContainer>
      <SectionSubtitleContainer>
        <SectionSubtitle>
          {t(
            `authSideBar.profile.${
              isAdminUser ? 'subTitleAdmin' : 'subTitleUser'
            }`
          )}
        </SectionSubtitle>
      </SectionSubtitleContainer>
      <UserProfileContainer userImage={userImage}>
        <div className='user-profile__photo-section'>
          <div className='user-profile__photo-container'>
            <AuthRightSideBarPhotoUpload />
            <div className='user-profile__photo' />
            <div className='user-profile__photo-container--dot1' />
            <div className='user-profile__photo-container--dot2' />
            <div className='user-profile__photo-container--dot3' />
            <div className='user-profile__photo-container--dot4' />
          </div>
        </div>
        <div className='user-profile__user-details-section'>
          <div className='user-profile__user-name'>
            {firstName}&nbsp;{lastName}
          </div>
          <div className='user-profile__user-position'>
            {t(`jobFunction.${jobFunction}`)}
          </div>
        </div>
      </UserProfileContainer>
    </SectionContainer>
  )
}

const avatarHeight = 125
export const UserProfileContainer = styled.div`
  margin-top: 17px;

  .user-profile__photo-section {
    text-align: center;
  }
  
  .user-profile__photo-container {
    position: relative;
    display: inline-block;
  }
  
  .user-profile__photo-container--dot1 {
    position: absolute;
    background-color: ${props => props.theme.palette.primary.main};
    width: 8px;
    height: 8px;
    border-radius: 50%;
    bottom: 0;
    left: 0;
    animation: ${floating1} 6s ease-in-out infinite;
  }
  .user-profile__photo-container--dot2 {
    position: absolute;
    background-color: ${props => props.theme.palette.primary.main};
    width: 6px;
    height: 6px;
    border-radius: 50%;
    top: 12px;
    right: 0;
    animation: ${floating2} 7s ease-in-out infinite;
  }
  .user-profile__photo-container--dot3 {
    position: absolute;
    background-color: ${props => props.theme.palette.primary.main};
    width: 8px;
    height: 8px;
    border-radius: 50%;
    top: 28px;
    right: -21px;
    animation: ${floating3} 8s ease-in-out infinite;
  }
  .user-profile__photo-container--dot4 {
    position: absolute;
    background-color: ${props => props.theme.palette.primary.main};
    width: 4px;
    height: 4px;
    border-radius: 50%;
    top: 60px;
    right: -13px;
    animation: ${floating4} 9s ease-in-out infinite;
  }

  .user-profile__photo {
    background-image: url('${props => props.userImage}');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    font-size: 30px;
    height: ${avatarHeight}px;
    width: ${avatarHeight + 8}px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .user-profile__photo::after {
    content: '';
    position: absolute;
    top: -1px;
    right: 0;
    height: ${avatarHeight + 3}px;
    width: ${avatarHeight + 9}px;
    background-image: url('${PhotoFrame}');
    background-repeat: no-repeat;
  }
  
  .user-profile__user-details-section {
    margin-top: 22px;
    text-align: center;
  }
  
  .user-profile__user-name {
    color: ${props => props.theme.palette.common.mineShaft};
    font-size: 18px;
    font-weight: 400;
  }
  
  .user-profile__user-position {
    color: ${props => props.theme.palette.common.mineShaft};
    font-size: 13px;
    font-weight: 300;
  }
`

export default AuthRightSideBarMyProfileSection
