import { API_STATES } from 'src/utils/api'
import { applySpec, map, pipe, prop, propEq, propOr, sortBy, concat } from 'ramda';
import { createSelector } from 'reselect'
import { transformToArray } from 'src/utils/redux'
import { isNotNilOrEmpty } from 'src/utils/ramda'

export const selectCoreValues = state => state.coreValues

export const isCoreValuesLoading = createSelector(
  selectCoreValues,
  propEq('state', API_STATES.IN_PROGRESS)
)

export const getCoreValuesList = createSelector(
  selectCoreValues,
  pipe(propOr({}, 'coreValues'), transformToArray, sortBy(prop('name')))
)

export const getHasAnyCoreValues = createSelector(
  selectCoreValues,
  pipe(
    propOr({}, 'coreValues'),
    isNotNilOrEmpty
  )
)

export const getCoreValuesAsMentions = createSelector(
  selectCoreValues,
  pipe(
    propOr({}, 'coreValues'),
    transformToArray,
    sortBy(prop('name')),
    map(
      applySpec({
        id: propOr('', 'name'),
        display: pipe(
          propOr('', 'name'),
          concat('#')
        )
      })
    )
  )
)

export const getCoreValuesAsOptions = createSelector(
  selectCoreValues,
  pipe(
    propOr({}, 'coreValues'),
    transformToArray,
    sortBy(prop('name')),
    map(
      applySpec({
        value: propOr('', 'name'),
        label: pipe(
          propOr('', 'name'),
          concat('#')
        )
      })
    )
  )
)
