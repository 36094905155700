import React, { useState } from 'react'
import styled from 'styled-components'
import { Menu, MenuItem } from 'de-suite-me-ui'

import DotsMenu from 'src/components/icons/DotsMenu'

export const MoreDropdownMenu = props => {
  const { data } = props
  const [isMenuOpen, setMenuVisibility] = useState(null)

  const openMenu = () => setMenuVisibility(true)
  const hideMenu = () => setMenuVisibility(false)

  const renderItems = data.map((element, index) => (
    <MenuItem
      // eslint-disable-next-line react/jsx-handler-names
      onClick={element.onClick}
      key={`menu-item-${index}-${element.name}`}
    >
      {element.name}
    </MenuItem>
  ))

  return (
    <Menu
      open={isMenuOpen}
      handleClose={hideMenu}
      handleOpen={openMenu}
      trigger={
        <MenuTriggerButton>
          <DotsMenu />
        </MenuTriggerButton>
      }
    >
      {renderItems}
    </Menu>
  )
}

MoreDropdownMenu.defaultProps = {
  data: []
}

export default MoreDropdownMenu

const MenuTriggerButton = styled.div`
  cursor: pointer;
  font-size: 18px;
  color: ${props => props.theme.palette.common.alto};
  transition: ${props =>
    `color ${props.theme.transitions.duration.standard}ms ${props.theme.transitions.easing.easeInOut}`};

  &:hover {
    color: ${props => props.theme.palette.primary.main};
  }
`
