import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import EditCoreValueForm from 'src/features/coreValues/EditCoreValueForm'
import Modal from 'src/components/atoms/Modal'
import IconButton from 'src/components/atoms/IconButton'
import Edit from 'src/components/icons/Edit'

export const UpdateCoreValueModal = (props) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <IconButton size='small' onClick={handleOpen}>
        <Edit />
      </IconButton>
      <Modal
        title={t('organisationSettingsPage.coreValues.edit')}
        open={open}
        handleClose={handleClose}
      >
        <EditCoreValueForm data={props.data} onSubmit={handleClose} />
      </Modal>
    </>
  )
}

export default UpdateCoreValueModal
