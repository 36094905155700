import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isNotNilOrEmpty } from 'src/utils/ramda'

import { fetchAllAppreciationsRoutine } from 'src/features/appreciations/duck/actions'
import {
  getAppreciations,
  getPagination
} from 'src/features/appreciations/duck/selectors'

import InfiniteScroll from 'src/components/InfiniteSroll'
import Appreciation from 'src/features/appreciations/components/Appreciation'
import AppreciationsEmptyState from 'src/features/appreciations/components/AppreciationsEmptyState'

export const AppreciationsList = props => {
  const { scrollContainerRef } = props

  // REDUX
  const dispatch = useDispatch()
  const appreciations = useSelector(getAppreciations)
  const pagination = useSelector(getPagination)
  const fetchAllAppreciations = useCallback(
    query => dispatch(fetchAllAppreciationsRoutine(query)),
    [dispatch]
  )

  return (
    <>
      <InfiniteScroll
        scrollContainerRef={scrollContainerRef}
        fetchRecords={fetchAllAppreciations}
        data={appreciations}
        component={Appreciation}
        pagination={pagination}
        additionalQuery={{
          order: {
            by: 'created_at',
            dir: 'DESC'
          }
        }}
      />
      {!isNotNilOrEmpty(appreciations) && <AppreciationsEmptyState />}
    </>
  )
}

export default AppreciationsList
