import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getPointsName } from 'src/utils/appreciations'

import { fetchPointsSummaryRoutine } from 'src/features/auth/duck/actions'
import {
  getAppreciatedByNumber,
  getAppreciationsGivenNumber,
  getPointsReceivedNumber
} from 'src/features/auth/duck/selectors'
import {
  getHasPointSystem, getOrganisationPointsPluralName
} from 'src/features/organisation/duck/selectors'

import {
  SectionContainer,
  SectionTitleContainer,
  SectionTitle,
  SectionSubtitleContainer,
  SectionSubtitle
} from 'src/features/auth/components/AuthRightSideBar/styled'
import Heart from 'src/components/icons/Heart'
import User from 'src/components/icons/User'

export const AuthRightSideBarSummarySection = props => {
  //  REDUX
  const dispatch = useDispatch()
  const appreciatedByNumber = useSelector(getAppreciatedByNumber)
  const pointsReceivedNumber = useSelector(getPointsReceivedNumber)
  const hasPointSystem = useSelector(getHasPointSystem)
  const appreciationsGivenNumber = useSelector(getAppreciationsGivenNumber)
  const pointsPluralName = useSelector(getOrganisationPointsPluralName)
  const fetchPointsSummary = useCallback(
    () => dispatch(fetchPointsSummaryRoutine()),
    [dispatch]
  )

  const { t } = useTranslation()

  useEffect(() => {
    fetchPointsSummary()
  }, [])

  const PersonSummary = (
    <td width='50%'>
      <div className='appreciations-summary-section__summary-container'>
        <div className='appreciations-summary-section__summary-icon appreciations-summary-section__summary-icon-user'>
          <User width='20px' height='20px' />
        </div>
        <div className='appreciations-summary-section__summary-description'>
          <div className='appreciations-summary-section__summary-numbers'>
            <span className='appreciations-summary-section__summary-count'>
              {appreciatedByNumber}
            </span>
            &nbsp;
            <span className='appreciations-summary-section__summary-text'>
              {t('authSideBar.points.person', { count: appreciatedByNumber })}
            </span>
          </div>
          <div className='appreciations-summary-section__summary-text-bottom'>
            {t('authSideBar.points.give', { count: appreciatedByNumber })}
          </div>
        </div>
      </div>
    </td>
  )

  const AppreciationsSummary = (
    <td width='50%'>
      <div className='appreciations-summary-section__summary-container'>
        <div className='appreciations-summary-section__summary-icon appreciations-summary-section__summary-icon-heart'>
          <Heart />
        </div>
        <div className='appreciations-summary-section__summary-description'>
          <div className='appreciations-summary-section__summary-numbers'>
            <span className='appreciations-summary-section__summary-count'>
              {hasPointSystem ? pointsReceivedNumber : appreciationsGivenNumber}
            </span>
            &nbsp;
            <span className='appreciations-summary-section__summary-text'>
              {hasPointSystem
                ? getPointsName(t, pointsReceivedNumber, pointsPluralName)
                : t('authSideBar.points.personAppreciated', {
                    count: appreciationsGivenNumber
                  })}
            </span>
          </div>
          <div className='appreciations-summary-section__summary-text-bottom'>
            {hasPointSystem
              ? t('authSideBar.points.appreciations')
              : t('authSideBar.points.appreciated')}
          </div>
        </div>
      </div>
    </td>
  )

  return (
    <SectionContainer>
      <SectionTitleContainer>
        <SectionTitle>
          {t(
            `authSideBar.points.${
              hasPointSystem ? 'titlePoints' : 'titleNonPoints'
            }`
          )}
        </SectionTitle>
      </SectionTitleContainer>
      <SectionSubtitleContainer>
        <SectionSubtitle>{t('authSideBar.points.subTitle')}</SectionSubtitle>
      </SectionSubtitleContainer>
      <AppreciationsSummaryContainer>
        <div className='appreciations-summary-section__wrapper'>
          <table width='100%'>
            <tbody>
              <tr>
                {PersonSummary}
                {AppreciationsSummary}
              </tr>
            </tbody>
          </table>
        </div>
      </AppreciationsSummaryContainer>
    </SectionContainer>
  )
}

const AppreciationsSummaryContainer = styled.div`
  margin: 17px 0 0;
  display: inline-block;
  width: 100%;

  .appreciations-summary-section__wrapper {
    box-sizing: border-box;
    padding: 6px 0;
    min-height: 63px;
    background-color: ${props => props.theme.palette.common.whisper};
    border-radius: 17px;
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  table,
  tbody,
  tr {
    display: inline-block;
    width: 100%;
  }

  td {
    padding: 0 10px;
    height: 49px;
    border: 2px solid
      ${props => props.theme.palette.common.dustyGrayTransparent};
  }

  td:first-child {
    border-left: 0;
    border-top: 0;
    border-bottom: 0;
  }

  td:last-child {
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
  }

  .appreciations-summary-section__summary-container {
    display: flex;
    align-items: center;
  }

  .appreciations-summary-section__summary-icon {
    flex: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.palette.common.white};
  }

  .appreciations-summary-section__summary-icon-heart {
    color: ${props => props.theme.palette.primary.main};
    font-size: 17px;
  }

  .appreciations-summary-section__summary-icon-user {
    color: ${props => props.theme.palette.secondary.main};
    font-size: 17px;
  }

  .appreciations-summary-section__summary-description {
    margin-left: 10px;
    word-break: break-word;
  }

  .appreciations-summary-section__summary-numbers {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .appreciations-summary-section__summary-count {
    font-size: 15px;
    font-weight: 600;
    color: ${props => props.theme.palette.common.mineShaft};
  }

  .appreciations-summary-section__summary-text {
    text-transform: capitalize;
    font-size: 13px;
    font-weight: 300;
    color: ${props => props.theme.palette.common.mineShaft};
  }

  .appreciations-summary-section__summary-text-bottom {
    font-size: 12px;
    font-weight: 300;
    color: ${props => props.theme.palette.common.mineShaft};
  }
`

export default AuthRightSideBarSummarySection
