import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isNotNilOrEmpty } from 'src/utils/ramda'

import { fetchReceivedByAuthUserAppreciationsRoutine } from 'src/features/appreciations/duck/actions'
import {
  getAuthUserReceivedAppreciations,
  getReceivedPagination
} from 'src/features/appreciations/duck/selectors'

import InfiniteScroll from 'src/components/InfiniteSroll'
import Appreciation from 'src/features/appreciations/components/Appreciation'
import AppreciationsEmptyState from 'src/features/appreciations/components/AppreciationsEmptyState'

export const ReceivedByAuthUserAppreciationsList = props => {
  const { scrollContainerRef } = props

  // REDUX
  const dispatch = useDispatch()
  const appreciations = useSelector(getAuthUserReceivedAppreciations)
  const pagination = useSelector(getReceivedPagination)
  const fetchAppreciations = useCallback(
    query => dispatch(fetchReceivedByAuthUserAppreciationsRoutine(query)),
    [dispatch]
  )

  return (
    <>
      <InfiniteScroll
        scrollContainerRef={scrollContainerRef}
        fetchRecords={fetchAppreciations}
        data={appreciations}
        component={Appreciation}
        pagination={pagination}
        additionalQuery={{
          order: {
            by: 'created_at',
            dir: 'DESC'
          }
        }}
      />
      {!isNotNilOrEmpty(appreciations) && <AppreciationsEmptyState />}
    </>
  )
}

export default ReceivedByAuthUserAppreciationsList
