import React, { useEffect, useState } from 'react';
import { isEmpty } from 'ramda';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { isNotNilOrEmpty } from 'src/utils/ramda'
import { MentionsInput, Mention } from 'react-mentions'
import { emojiIndex } from 'emoji-mart'

import { appreciationFormFields } from 'src/utils/appreciations'

export const CreateAppreciationContent = props => {
  const { onChange, name, onBlur, onFocus, value, placeholder } = props
  const [isFocused, setIsFocused] = useState(false)
  const { t } = useTranslation()

  const handleFocus = () => {
    onFocus(name)
    setIsFocused(true)
  }
  const handleBlur = () => {
    onBlur(name)
    setIsFocused(false)
  }

  return (
    <>
      <span className='appreciation-input__separator'>
        &nbsp;/&nbsp;
        <ForSeparator isHighlighted={isFocused || isNotNilOrEmpty(value)}>
          {t('appreciations.for')}
        </ForSeparator>
        &nbsp;
      </span>
      <InputContainer>
        <TaggedInput
          placeholder={placeholder}
          value={value}
          size={5}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={onChange}
        />
      </InputContainer>
    </>
  )
}

CreateAppreciationContent.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {}
}

export default CreateAppreciationContent

const TaggedInput = props => {
  const { onBlur, onFocus, placeholder, size, value } = props
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (isEmpty(value)) {
      setInputValue('')
    }
  }, [value])

  const searchEmoji = (query, callback) => {
    if (query) {
      const emojisToSelect = emojiIndex.search(query).map(emojiObject => ({
        id: emojiObject.id,
        display: emojiObject.native
      }))

      return callback(emojisToSelect)
    }
  }

  const handleChange = (
    event,
    newValue,
    newPlainTextValue,
  ) => {
    setInputValue(newValue)
    props.onChange(appreciationFormFields.content, newPlainTextValue)
  }

  return (
    <StyledMentionsInput
      placeholder={placeholder}
      size={size}
      value={inputValue}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={handleChange}
      id='appreciation-content'
    >
      <Mention
        trigger=':'
        markup=':[__display__](__id__)'
        displayTransform={(id, display) => display}
        data={searchEmoji}
      />
    </StyledMentionsInput>
  )
}

const InputContainer = styled.div`
  flex: 2;
  min-height: 19px;
  
  textarea::placeholder {
    color: ${props => props.theme.palette.common.alto};
  }
  
  ${props => props.theme.breakpointsMedia.mobile} {
    flex: auto;
    min-height: 15px;
  }
`

const ForSeparator = styled.span`
  color: ${props =>
    props.isHighlighted
      ? props.theme.palette.text.main
      : props.theme.palette.common.alto};
`

const StyledMentionsInput = styled(MentionsInput)`
  textarea {
    border: none !important;
    padding: 0 !important;
  }
  
  div {
    min-width: auto !important;
  }
  
  ul,
  ol {
    background-color: ${props => props.theme.palette.common.white} !important;
    border-radius: ${props => props.theme.shape.borderRadius} !important;
    padding: 8px 0 !important;
    box-shadow: ${props => props.theme.shadows.main} !important;
    max-height: 150px !important;
    min-width: auto !important;
    overflow-y: auto !important;
  }
  
  li {
    padding: 6px 16px !important;
    transition: background-color ${props => props.theme.transitions.duration.shortest}ms ${props => props.theme.transitions.easing.easeInOut} !important;
  }
  
  li:hover,
  li:focus {
    background-color: ${props => props.theme.palette.common.catskillWhite} !important;
  }
  
  li[class$="focused"] {
    background-color: ${props => props.theme.palette.common.catskillWhite} !important;
  }
`
