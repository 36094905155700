import React from 'react'
import theme from 'src/theme/theme'
import { components } from 'react-select'
import { isNotNilOrEmpty } from 'src/utils/ramda'
import Button from 'src/components/atoms/Button'
import styled from 'styled-components'

const dropdownStyles = {
  menu: (provided, state) => {
    return {
      position: 'absolute',
      top: '100%',
      left: '0',
      margin: '8px 0',
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows.main,
      zIndex: theme.zIndex.dropdown
    }
  },
  menuList: (provided, state) => {
    return {
      backgroundColor: theme.palette.common.white,
      maxHeight: '300px',
      overflowY: 'auto',
      boxSizing: 'border-box',
      zIndex: theme.zIndex.dropdown
    }
  },
  option: (provided, state) => {
    const { isFocused } = state
    return {
      color: theme.palette.text.main,
      fontFamily: theme.typography.fontFamily,
      zIndex: theme.zIndex.dropdown,
      cursor: 'pointer',
      fontSize: '13px',
      padding: '6px 16px',
      lineHeight: '1.5',
      overflow: 'visible',
      whiteSpace: 'nowrap',
      backgroundColor: isFocused
        ? theme.palette.common.catskillWhite
        : theme.palette.common.white,
      transition: 'background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
      '&:hover': {
        backgroundColor: theme.palette.common.catskillWhite
      },
      '&:active': {
        backgroundColor: theme.palette.common.catskillWhite
      },
      '&:focus': {
        backgroundColor: theme.palette.common.alto
      }
    }
  },
  noOptionsMessage: (provided, state) => {
    return {
      color: theme.palette.text.main,
      fontFamily: theme.typography.fontFamily,
      zIndex: theme.zIndex.dropdown,
      cursor: 'pointer',
      fontSize: '13px',
      padding: '6px 16px',
      lineHeight: '1.5',
      overflow: 'visible',
      whiteSpace: 'nowrap',
      backgroundColor: theme.palette.common.white
    }
  }
}

export const SELECT_POINTS_REACT_SELECT_STYLES = {
  input: (provided, state) => {
    return {
      width: '100%',
      'input::placeholder': {
        color: theme.palette.common.alto
      }
    }
  },
  control: (provided, state) => {
    return {
      display: 'flex',
      alignItems: 'center',
      minWidth: '30px'
    }
  },
  container: (provided, state) => {
    return {
      ...provided,
      display: 'flex',
      alignItems: 'center'
    }
  },
  valueContainer: (provided, state) => {
    return {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: 0,
      overflow: 'visible'
    }
  },
  singleValue: (provided, state) => {
    return {
      color: theme.palette.text.main
    }
  },
  indicatorSeparator: (provided, state) => {
    return { display: 'none' }
  },
  indicatorsContainer: (provided, state) => {
    return { display: 'none' }
  },
  placeholder: (provided, state) => {
    return { ...provided, display: 'none' }
  },
  ...dropdownStyles
}

export const REACT_SELECT_STYLES = {
  input: (provided, state) => {
    return {
      'input::placeholder': {
        color: theme.palette.common.alto
      }
    }
  },
  control: (provided, state) => {
    return {
      display: 'flex',
      alignItems: 'center'
    }
  },
  container: (provided, state) => {
    return {
      ...provided,
      display: 'flex',
      alignItems: 'center'
    }
  },
  indicatorSeparator: (provided, state) => {
    return { display: 'none' }
  },
  indicatorsContainer: (provided, state) => {
    return { display: 'none' }
  },
  placeholder: (provided, state) => {
    return { ...provided, display: 'none' }
  },
  valueContainer: (provided, state) => {
    return {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: 0,
      overflow: 'visible'
    }
  },
  multiValue: (provided, state) => ({ display: 'none' }),
  multiValueLabel: provided => ({ display: 'none' }),
  multiValueRemove: provided => ({ display: 'none' }),
  ...dropdownStyles
}

export const CORE_VALUES_SELECT_STYLES = {
  input: (provided, state) => {
    return {
      'input::placeholder': {
        color: theme.palette.common.alto
      }
    }
  },
  control: (provided, state) => {
    return {
      display: 'flex',
      alignItems: 'center'
    }
  },
  container: (provided, state) => {
    return {
      ...provided,
      display: 'flex',
      alignItems: 'center'
    }
  },
  indicatorSeparator: (provided, state) => {
    return { display: 'none' }
  },
  indicatorsContainer: (provided, state) => {
    return { display: 'none' }
  },
  placeholder: (provided, state) => {
    return { ...provided, display: 'none' }
  },
  valueContainer: (provided, state) => {
    return {
      display: 'block',
      width: '0',
      padding: 0
    }
  },
  multiValue: (provided, state) => ({ display: 'none' }),
  multiValueLabel: provided => ({ display: 'none' }),
  multiValueRemove: provided => ({ display: 'none' }),
  ...dropdownStyles,
  option: (provided, state) => {
    const { isFocused, isSelected } = state

    return {
      color: isSelected ? theme.palette.primary.main : theme.palette.text.main,
      fontFamily: theme.typography.fontFamily,
      zIndex: theme.zIndex.dropdown,
      cursor: 'pointer',
      fontSize: '13px',
      padding: '6px 16px',
      lineHeight: '1.5',
      overflow: 'visible',
      whiteSpace: 'nowrap',
      backgroundColor: isFocused
        ? theme.palette.common.catskillWhite
        : theme.palette.common.white,
      transition: 'background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
      '&:hover': {
        backgroundColor: theme.palette.common.catskillWhite
      },
      '&:active': {
        backgroundColor: theme.palette.common.catskillWhite
      },
      '&:focus': {
        backgroundColor: theme.palette.common.alto
      }
    }
  },
}

export const CustomInput = props => {
  const { selectProps } = props
  const hasValue = isNotNilOrEmpty(selectProps.value)

  return (
    <components.Input
      {...props}
      placeholder={hasValue ? '' : selectProps.placeholder}
    />
  )
}

export const CoreValuesCustomControl = props => {
  const { selectProps } = props
  const selectedOptionsLength = selectProps.value.length

  const counter = selectedOptionsLength > 0 ? ` (${selectedOptionsLength})` : ''

  return (
    <>
      <components.Control
        {...props}
      >
        <StyledCoreValuesButton id='add-core-values-button' size='small' variant='outlined'>
          {selectProps.placeholder}{counter}
        </StyledCoreValuesButton>
        {props.children}
      </components.Control>
    </>
  )
}

export const customComponents = {
  Input: CustomInput
}

export const customCoreValuesComponents = {
  Input: CustomInput,
  Control: CoreValuesCustomControl
}

export const StyledCoreValuesButton = styled(Button)`
  color: ${props => props.theme.palette.common.gray};
  border-color: ${props => props.theme.palette.common.alto};
  
  &:hover:enabled {
    background: ${props => props.theme.palette.common.white} radial-gradient(circle, transparent 1%, ${props => props.theme.palette.common.alto} 1%) center/15000%;
    border-color: ${props => props.theme.palette.common.alto};
  }
  
  &:active:enabled {
    background-color: ${props => props.theme.palette.common.alto};
    border-color: ${props => props.theme.palette.common.alto};
  }
`
