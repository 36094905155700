import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { propOr } from 'ramda'
import {
  validateUpdateProfileField,
  validateUpdateProfileValues
} from 'src/features/auth/duck/schema'
import { jobFunctionOptions } from 'src/utils/jobFunction'
import { META } from 'src/utils/seo'

import { updateAuthUserRoutine } from 'src/features/auth/duck/actions'
import { getAuthUser, isAuthLoading } from 'src/features/auth/duck/selectors'

import Metadata from 'src/components/Metadata'
import Form from 'src/components/atoms/Form'
import Input from 'src/components/atoms/Input'
import Select from 'src/components/atoms/Select'
import Button from 'src/components/atoms/Button'
import Paper from 'src/components/atoms/Paper'
import AuthPageTitle from 'src/components/atoms/AuthPageTitle'
import Grid from 'src/components/atoms/Grid'
import PaddingContainer from 'src/components/PaddingContainer'
import { USER_ROLES } from '../../utils/user'

const emptyValues = {
  firstName: '',
  lastName: '',
  nickname: '',
  phoneNumber: '',
  jobFunction: ''
}

export const UpdateProfile = props => {
  //  REDUX
  const dispatch = useDispatch()
  const isLoading = useSelector(isAuthLoading)
  const authUser = useSelector(getAuthUser)
  const updateAuthUser = useCallback(
    payload => dispatch(updateAuthUserRoutine(payload)),
    [dispatch]
  )

  const [valid, _validateSchema] = useState(true)
  const [values, setValues] = useState(emptyValues)
  const [submitting, setIsSubmitting] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    isLoading === false && setIsSubmitting(false)
  }, [isLoading])

  useEffect(() => {
    setInitialValues()
  }, [authUser])

  useEffect(() => {
    validateUpdateProfileValues(values, _validateSchema)
  }, [values])

  const setInitialValues = () => {
    return setValues({
      firstName: propOr('', 'firstName')(authUser),
      lastName: propOr('', 'lastName')(authUser),
      phoneNumber: propOr('', 'phoneNumber')(authUser),
      jobFunction: propOr('', 'jobFunction')(authUser)
    })
  }

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = e => {
    e.preventDefault()
    setIsSubmitting(true)
    updateAuthUser(values)
  }

  const UpdateProfileForm = (
    <Form onSubmit={handleSubmit}>
      <Input
        value={propOr('', 'firstName')(authUser)}
        required
        name='firstName'
        label={t('form.firstName.label')}
        onChange={handleOnChange}
        validate={validateUpdateProfileField(values)}
      />
      <Input
        value={propOr('', 'lastName')(authUser)}
        required
        name='lastName'
        label={t('form.lastName.label')}
        onChange={handleOnChange}
        validate={validateUpdateProfileField(values)}
      />
      <Input
        value={propOr('', 'nickname')(authUser)}
        name='nickname'
        label={t('form.nickname.label')}
        onChange={handleOnChange}
        validate={validateUpdateProfileField(values)}
      />
      <Input
        value={propOr('', 'phoneNumber')(authUser)}
        name='phoneNumber'
        label={t('form.phoneNumber.label')}
        onChange={handleOnChange}
        validate={validateUpdateProfileField(values)}
      />
      <Select
        value={propOr('', 'jobFunction')(authUser)}
        required
        options={jobFunctionOptions()}
        name='jobFunction'
        label={t('form.jobFunction.label')}
        validate={validateUpdateProfileField(values)}
        onChange={handleOnChange}
      />
      {
        USER_ROLES.admin !== authUser.role && (
          <p>
            {t('myAccountPage.changeEmail')}
          </p>
        )
      }
      <ButtonContainer>
        <Button
          type='submit'
          disabled={(isLoading && submitting) || !valid}
          onClick={handleSubmit}
        >
          {t('myAccountPage.updateCTA')}
        </Button>
      </ButtonContainer>
    </Form>
  )

  return (
    <PaddingContainer>
      <AuthPageTitle>{t('myAccountPage.pageTitle')}</AuthPageTitle>
      <Metadata meta={META.myAccount} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2} />
        <Grid item xs={12} sm={8}>
          <Paper variant='outlined'>{UpdateProfileForm}</Paper>
        </Grid>
      </Grid>
    </PaddingContainer>
  )
}

export default UpdateProfile

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
