import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import {
  CORE_VALUES_SELECT_STYLES,
  customCoreValuesComponents
} from 'src/features/appreciations/components/CreateAppreciationInput/components/styles'
import { filter, includes, map, prop } from 'ramda'
import {
  getCoreValuesAsOptions,
  getHasAnyCoreValues
} from 'src/features/coreValues/duck/selectors';

export const AssignCoreValues = props => {
  const { onChange, onBlur, onFocus, name, selectedValues } = props
  const coreValuesOptions = useSelector(getCoreValuesAsOptions)
  const hasAnyCoreValues = useSelector(getHasAnyCoreValues)

  const getSelectedValues = () =>
    filter(
      option => includes(option.value, selectedValues)
    )(coreValuesOptions)

  // STATE
  const { t } = useTranslation()

  const handleOnBlur = () => onBlur(name)
  const handleOnFocus = () => onFocus(name)
  const handleChange = selectedOption => {
    const coreValues = map(prop('value'))(selectedOption)
    onChange(name, coreValues)
  }

  return hasAnyCoreValues && (
    <>
      <Select
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        controlShouldRenderValue={false}
        isSearchable={false}
        isMulti
        options={coreValuesOptions}
        value={getSelectedValues()}
        styles={CORE_VALUES_SELECT_STYLES}
        components={customCoreValuesComponents}
        placeholder={t('appreciations.coreValues')}
        isClearable={false}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        onChange={handleChange}
        id='add-core-values'
        instanceId='add-core-values'
      />
    </>
  )
}

AssignCoreValues.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {}
}

export default AssignCoreValues
