import React from 'react'
import styled from 'styled-components'
import AuthRightSideBarMyProfileSection from 'src/features/auth/components/AuthRightSideBar/AuthRightSideBarMyProfileSection'
import AuthRightSideBarPointsSection from 'src/features/auth/components/AuthRightSideBar/AuthRightSideBarSummarySection'
import AuthRightSideBarRankingSection from 'src/features/auth/components/AuthRightSideBar/AuthRightSideBarRankingSection'

export const AuthRightSideBar = props => {
  return (
    <>
      <AuthRightSideBarContainer>
        <AuthRightSideBarMyProfileSection />
        <AuthRightSideBarPointsSection />
        <AuthRightSideBarRankingSection />
      </AuthRightSideBarContainer>
      <AuthRightSideBarPlaceholder />
    </>
  )
}

export default AuthRightSideBar

const AuthRightSideBarPlaceholder = styled.div`
  width: ${props => props.theme.dimensions.authRightSideBarWidth};

  ${props => props.theme.breakpointsMedia.mobile} {
    display: none;
  }
`

const AuthRightSideBarContainer = styled.div`
  box-sizing: border-box;
  z-index: ${props => props.theme.zIndex.drawer};
  padding: 26px 23px;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: ${props => props.theme.dimensions.authRightSideBarWidth};
  background-color: ${props => props.theme.palette.common.white};
  color: ${props => props.theme.palette.primary.main};
  overflow-y: auto;
  box-shadow: ${props => props.theme.shadows.main};

  ${props => props.theme.breakpointsMedia.mobile} {
    position: static;
    height: 100%;
    width: 100%;
  }
`
