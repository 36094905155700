import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { map, pipe, propEq, propOr } from 'ramda'
import { userRoleOptions } from 'src/utils/user'

import {
  getOrganisationUsers,
  getOrganisationUsersMeta
} from 'src/features/users/duck/selectors'
import { getAuthUser } from 'src/features/auth/duck/selectors';
import {
  deleteUserRoutine,
  getOrganisationUsersRoutine,
  toggleUserActivityRoutine,
  updateUserRoleRoutine
} from 'src/features/users/duck/actions'

import {
  Table,
  TableBody,
  TableCell,
  TableHeaderCell,
  TableHead,
  TableRow,
  TableFooter
} from 'src/components/atoms/Table/Table'
import Pagination from 'src/components/atoms/Table/Pagination'
import EmptyState from 'src/components/EmptyState'
import Toggle from 'src/components/atoms/Toggle'
import DeleteButtonWithConfirm from 'src/components/DeleteButtonWithConfirm'
import EditUserModal from 'src/features/users/components/EditUserModal'
import Select from 'src/components/atoms/Select'

export const UsersList = props => {
  //  REDUX
  const dispatch = useDispatch()
  const users = useSelector(getOrganisationUsers)
  const usersPagination = useSelector(getOrganisationUsersMeta)
  const authUser = useSelector(getAuthUser)
  const isAuthUser = id => propEq('id', id)(authUser)

  const fetchOrganisationUsers = useCallback(
    query => dispatch(getOrganisationUsersRoutine(query)),
    [dispatch]
  )
  const deleteUser = useCallback(
    payload => dispatch(deleteUserRoutine(payload)),
    [dispatch]
  )
  const updateUserRole = useCallback(
    payload => dispatch(updateUserRoleRoutine(payload)),
    [dispatch]
  )
  const toggleUserActivity = useCallback(
    payload => dispatch(toggleUserActivityRoutine(payload)),
    [dispatch]
  )

  const { t } = useTranslation()

  const handleToggleActivity = user => () =>
    toggleUserActivity({ id: user.id, isActive: !user.isActive })
  const handleUpdateUser = id => (name, value) =>
    updateUserRole({ id, role: value })

  const UserRow = user => (
    <TableRow key={user.id}>
      <TableCell>{user.firstName}</TableCell>
      <TableCell>{user.lastName}</TableCell>
      <TableCell>{user.nickname}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>
        {isAuthUser(user.id) ? (
          t(`userRoles.${user.role}`)
        ) : (
          <Select
            value={user.role}
            options={userRoleOptions()}
            name='role'
            label={t('form.role.label')}
            onChange={handleUpdateUser(user.id)}
          />
        )}
      </TableCell>
      <TableCell>{user.phoneNumber}</TableCell>
      <TableCell>{t(`jobFunction.${user.jobFunction}`)}</TableCell>
      <TableCell>
        {!isAuthUser(user.id) && (
          <Toggle value={user.isActive} onChange={handleToggleActivity(user)} />
        )}
      </TableCell>
      <TableCell>
        <EditUserModal user={user} />
        {!isAuthUser(user.id) && (
          <DeleteButtonWithConfirm
            text={t('manageUsersPage.manageUsers.remove.confirmation')}
            submitText={t('manageUsersPage.manageUsers.remove.submit')}
            cancelText={t('manageUsersPage.manageUsers.remove.cancel')}
            id={user.id}
            onSubmit={deleteUser}
            canDelete={user.canDelete}
          />
        )}
      </TableCell>
    </TableRow>
  )

  const TableEmptyState = (
    <TableRow>
      <TableCell colSpan={8}>
        <EmptyStateContainer>
          <EmptyState text={t('manageUsersPage.manageUsers.empty')} />
        </EmptyStateContainer>
      </TableCell>
    </TableRow>
  )

  const renderRows = map(UserRow)(users)

  return (
    <TableContainer>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableHeaderCell>
              {t('manageUsersPage.manageUsers.tableHeaders.firstName')}
            </TableHeaderCell>
            <TableHeaderCell>
              {t('manageUsersPage.manageUsers.tableHeaders.lastName')}
            </TableHeaderCell>
            <TableHeaderCell>
              {t('manageUsersPage.manageUsers.tableHeaders.nickname')}
            </TableHeaderCell>
            <TableHeaderCell>
              {t('manageUsersPage.manageUsers.tableHeaders.email')}
            </TableHeaderCell>
            <TableHeaderCell>
              {t('manageUsersPage.manageUsers.tableHeaders.role')}
            </TableHeaderCell>
            <TableHeaderCell>
              {t('manageUsersPage.manageUsers.tableHeaders.phoneNumber')}
            </TableHeaderCell>
            <TableHeaderCell>
              {t('manageUsersPage.manageUsers.tableHeaders.jobFunction')}
            </TableHeaderCell>
            <TableHeaderCell>
              {t('manageUsersPage.manageUsers.tableHeaders.activity')}
            </TableHeaderCell>
            <TableHeaderCell />
          </TableRow>
        </TableHead>
        <TableBody>{users.length > 0 ? renderRows : TableEmptyState}</TableBody>
        <TableFooter>
          <TableRow>
            <Pagination
              pagination={usersPagination}
              fetchRecords={fetchOrganisationUsers}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default UsersList

const EmptyStateContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TableContainer = styled.div`
  display: inline-block;
  width: 100%;
  overflow-x: auto;
`
