import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isNotNilOrEmpty } from 'src/utils/ramda'
import { getHeadErrorOrEmptyObj } from 'src/utils/form'
import { CreatableSelect } from 'de-suite-me-ui'

import { isNil } from 'ramda'

export const MultiSelectCreatable = props => {
  const { onChange, name, autofocus, validate, label, required, reset } = props
  const [touched, _setTouched] = useState(false)
  const [value, _setValue] = useState(null)
  const [{ valid, error }, _validate] = useState({
    valid: true,
    error: { key: '', options: {} }
  })

  const { t } = useTranslation()

  useEffect(() => {
    if (touched && !reset) {
      validate(name, v => {
        _validate({ valid: v.valid, error: getHeadErrorOrEmptyObj(v) })
      })
    }
  }, [value, touched, reset])

  useEffect(() => {
    if (reset) {
      _setValue(null)
    }
  }, [reset])

  const isValidNewOption = inputValue =>
    isNotNilOrEmpty(inputValue) && /\S/.test(inputValue)

  const handleFocus = () => {
    _setTouched(true)
  }
  const handleChange = e => {
    const parsedNilValue = isNil(e) ? [] : e

    _setValue(parsedNilValue)
    onChange(name, parsedNilValue)
  }

  return (
    <>
      <CreatableSelect
        value={value}
        id={name}
        name={name}
        options={[]}
        label={label}
        required={required}
        autoFocus={autofocus}
        placeholder=''
        onChange={handleChange}
        onFocus={handleFocus}
        isValidNewOption={isValidNewOption}
        noResultsText=''
        formatCreateLabel={label =>
          `${t('manageUsersPage.invite.add')} ${label || ''}`
        }
        noOptionsMessage={() => null}
        onBlurResetsInput={false}
        onCloseResetsInput={false}
        openOnClick={false}
        isMulti
        isClearable
        errorText={valid ? '' : t(error.key, error.options)}
        error={!valid}
        createOptionPosition='first'
        instanceId={name}
      />
    </>
  )
}

MultiSelectCreatable.defaultProps = {
  onChange: e => {},
  validate: () => {}
}

export default MultiSelectCreatable
