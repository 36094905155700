import React, { useCallback } from 'react'
import styled from 'styled-components'

import Edit from 'src/components/icons/Edit'
import { useDispatch } from 'react-redux'
import {
  fetchAuthUserRoutine
} from 'src/features/auth/duck/actions'

import { uploadPhoto } from 'src/services/AuthService'
import { showToastRoutine } from 'src/features/toast/duck/actions'
import { includes } from 'ramda'
import { SEVERITY } from '../../../../utils/toast'

export const AuthRightSideBarPhotoUpload = props => {
  const dispatch = useDispatch()

  const fetchAuthUser = useCallback(() => dispatch(fetchAuthUserRoutine()), [
    dispatch
  ])

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const handleOnChange = e => {
    e.preventDefault()
    const acceptedFiles = ['image/jpeg', 'image/png', 'image/svg+xml']

    const hasValidFormat = includes(e.target.files[0].type, acceptedFiles)

    const handleSuccess = () => {
      fetchAuthUser()
      showToast({
        key: 'toast.uploadPhotoSuccess',
        severity: SEVERITY.success
      })
    }

    const handleError = () => {
      showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    }

    if (hasValidFormat) {
      uploadPhoto(e.target.files[0])
        .then(handleSuccess)
        .catch(handleError)
    } else {
      showToast({
        key: 'toast.invalidFileFormat',
        severity: SEVERITY.error
      })
    }
  }

  return (
    <UploadContainer>
      <label htmlFor='photo-upload'>
        <div className='photo-upload__button'>
          <Edit />
        </div>
      </label>
      <input
        id='photo-upload'
        onChange={handleOnChange}
        type='file'
      />
    </UploadContainer>
  )
}

export default AuthRightSideBarPhotoUpload

const UploadContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;

  .photo-upload__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    color: ${props => props.theme.palette.common.white};
    background-color: ${props => props.theme.palette.primary.main};
    
    &:hover {
      background-color: ${props => props.theme.palette.primary.light};
      cursor: pointer;
      transition: ${props =>
              `all ${props.theme.transitions.duration.complex}ms ${props.theme.transitions.easing.easeInOut}`};
    }
  }
  
  input {
    display: none;
  }
`
