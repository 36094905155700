import React from 'react'
import styled from 'styled-components'

import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'

import { useTranslation } from 'react-i18next'

export const EmojiPicker = props => {
  const { onChange, isOpen } = props
  const { t } = useTranslation()

  const handleSelectEmoji = emoji => onChange(emoji)

  return (
    <PickerContainer isOpen={isOpen}>
      <Picker
        i18n={t('emojiPicker', { returnObjects: true })}
        autoFocus
        emoji=''
        onSelect={handleSelectEmoji}
        showPreview={false}
        showSkinTones={false}
        title=''
      />
    </PickerContainer>
  )
}

export default EmojiPicker

const PickerContainer = styled.div`
  display: block;
  text-align: right;
  padding: 5px;
  padding-top: ${props => (props.isOpen ? '33px' : '0px')};
  height: ${props => (props.isOpen ? '400px' : '0px')};
  transition: ${props =>
    `height ${props.theme.transitions.duration.standard}ms ${props.theme.transitions.easing.easeInOut}`};

  .emoji-mart-category-label,
  .emoji-mart-category-list {
    text-align: left;
    font-size: 14px;
  }

  .emoji-mart-search {
    margin: 15px 0;

    input {
      font-size: 14px;
      border-radius: 4px;
      transition: ${props =>
        `all ${props.theme.transitions.duration.complex}ms ${props.theme.transitions.easing.easeInOut}`};
    }

    input:focus {
      border-color: ${props => props.theme.palette.primary.main};
    }

    input::placeholder {
      font-size: 14px;
    }
  }

  .emoji-mart {
    height: ${props => (props.isOpen ? '400px' : '0px')};
    display: ${props => (props.isOpen ? 'block' : 'none')};
    opacity: ${props => (props.isOpen ? '1' : '0')};
    width: 100% !important;
    border: none;
    font-family: ${props =>
      props.theme.typography.fontFamily};
    transition: ${props =>
      `all ${props.theme.transitions.duration.complex}ms ${props.theme.transitions.easing.easeInOut}`};
  }

  .emoji-mart-search {
    display: flex;
  }

  .emoji-mart-preview {
    height: 50px;
  }

  .emoji-mart-anchor:hover,
  .emoji-mart-anchor:focus,
  .emoji-mart-anchor-selected {
    color: ${props => props.theme.palette.primary.main} !important;

    .emoji-mart-anchor-bar {
      background-color: ${props =>
        props.theme.palette.primary.main} !important;
    }
  }
`
