import React, { useEffect, useState } from 'react'
import TablePagination from '@material-ui/core/TablePagination'
import { useTranslation } from 'react-i18next'
import qs from 'qs'

const recordsPerPage = 10

export const Pagination = props => {
  const { pagination, fetchRecords } = props
  const [rowsPerPage, setRowsPerPage] = useState(recordsPerPage)
  const { t } = useTranslation()

  useEffect(() => {
    fetchRecords(
      qs.stringify({
        limit: {
          page: 1,
          take: rowsPerPage
        }
      })
    )
  }, [])

  const handleChangePage = (event, newPage) => {
    fetchRecords(
      qs.stringify({
        limit: {
          // +1 because API pagination meta is counting from 1
          page: newPage + 1,
          take: rowsPerPage
        }
      })
    )
  }

  const handleChangeRowsPerPage = event => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setRowsPerPage(newRowsPerPage)
    fetchRecords(
      qs.stringify({
        limit: {
          page: 1,
          take: newRowsPerPage
        }
      })
    )
  }

  const getDisplayedRowsLabel = params => {
    const { from, to, count, page } = params
    return t('pagination.displayedRows', { from, to, count, page })
  }

  return (
    <TablePagination
      labelRowsPerPage={t('pagination.rowsPerPage')}
      labelDisplayedRows={getDisplayedRowsLabel}
      rowsPerPageOptions={[recordsPerPage, 25, 50]}
      // -1 because MUI pagination component is counting from 0
      page={Number(pagination.page) - 1}
      rowsPerPage={Number(pagination.take)}
      count={Number(pagination.recordsTotal)}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  )
}

export default Pagination
