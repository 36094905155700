import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { preventSubmitOnEnter } from 'src/utils/form'
import { userRoleOptions } from 'src/utils/user'
import {
  validateInviteUsersField,
  validateInviteUsersValues
} from 'src/features/users/duck/schema'
import { map, prop } from 'ramda'

import { isUsersLoading } from 'src/features/users/duck/selectors'
import { inviteUsersRoutine } from 'src/features/users/duck/actions'

import MultiSelectCreatable from 'src/components/atoms/MultiSelectCreatable'
import Select from 'src/components/atoms/Select'
import Button from 'src/components/atoms/Button'

const emptyValues = {
  emails: [],
  role: ''
}

export const InviteUsers = props => {
  const { onSubmit } = props
  //  REDUX
  const dispatch = useDispatch()
  const isLoading = useSelector(isUsersLoading)
  const inviteUsers = useCallback(
    payload => dispatch(inviteUsersRoutine(payload)),
    [dispatch]
  )

  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const [shouldReset, setShouldReset] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    validateInviteUsersValues(values, _validateSchema)
  }, [values])

  useEffect(() => {
    if (shouldReset) {
      setValues(emptyValues)
    }
  }, [shouldReset])

  const handleOnChange = (name, value) => {
    setValues({ ...values, [name]: value })
    setShouldReset(false)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const { emails, role } = values

    const payload = {
      emails: map(prop('value'))(emails),
      role
    }

    await inviteUsers(payload)
    onSubmit(e)
    setShouldReset(true)
  }

  return (
    <FormContainer onSubmit={handleSubmit} onKeyDown={preventSubmitOnEnter}>
      <div className='invite-users__emails-input'>
        <MultiSelectCreatable
          onChange={handleOnChange}
          required
          name='emails'
          label={t('form.emails.label')}
          validate={validateInviteUsersField(values)}
          reset={shouldReset}
        />
      </div>
      <div className='invite-users__role-input'>
        <Select
          required
          options={userRoleOptions()}
          name='role'
          label={t('form.role.label')}
          onChange={handleOnChange}
          validate={validateInviteUsersField(values)}
          reset={shouldReset}
        />
      </div>
      <ButtonContainer>
        <Button
          type='submit'
          disabled={isLoading || !valid}
          onClick={handleSubmit}
        >
          {t('manageUsersPage.invite.ctaButton')}
        </Button>
      </ButtonContainer>
    </FormContainer>
  )
}

InviteUsers.defaultProps = {
  onSubmit: () => {}
}

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 10px;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    width: 100%;
  }
  
  .MuiButtonBase-root {
    ${props => props.theme.breakpointsMedia.mobile} {
      width: 100%;
    }
  }
`

const FormContainer = styled.form`
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    flex-direction: column;
  }

  .invite-users__emails-input {
    flex: 1;
    margin-right: 10px;
    min-width: 150px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      width: 100%;
    }
  }

  .invite-users__role-input {
    min-width: 150px;
    margin-right: 10px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      width: 100%;
      margin: 0;
    }

    .MuiFormControl-root {
      margin: 0;
      
      ${props => props.theme.breakpointsMedia.mobile} {
        width: 100%;
      }
    }
  }
`

export default InviteUsers
