import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { propOr } from 'ramda'
import { isNotNilOrEmpty } from 'src/utils/ramda'
import { pointOptions } from 'src/utils/appreciations'

import { getOrganisationPointsGiveMinimum } from 'src/features/organisation/duck/selectors'

import {
  SELECT_POINTS_REACT_SELECT_STYLES,
  customComponents
} from 'src/features/appreciations/components/CreateAppreciationInput/components/styles'
import CreatableSelect from 'react-select/creatable/dist/react-select.esm'

export const CreateAppreciationPoints = props => {
  const { onChange, onBlur, onFocus, name, reset } = props

  // REDUX
  const minimumPoints = useSelector(getOrganisationPointsGiveMinimum)

  // STATE
  const { t } = useTranslation()
  const [value, setValue] = useState(null)

  useEffect(() => {
    if (reset) {
      setValue(null)
    }
  }, [reset])

  const handlePointsChange = selectedOption => {
    setValue(selectedOption)
    onChange(name, propOr(0, 'value', selectedOption))
  }

  const handleOnBlur = () => onBlur(name)
  const handleOnFocus = () => onFocus(name)
  const separatorClass = cx({
    'appreciation-input__separator': true,
    'appreciation-input__separator--selected': isNotNilOrEmpty(value)
  })

  return (
    <>
      <span className={separatorClass}>+</span>
      <CreatableSelect
        components={customComponents}
        value={value}
        styles={SELECT_POINTS_REACT_SELECT_STYLES}
        options={pointOptions(minimumPoints)}
        placeholder='100'
        isClearable
        onChange={handlePointsChange}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        formatCreateLabel={label =>
          `${t('manageUsersPage.invite.add')} ${label || ''}`
        }
        isValidNewOption={value => value % 5 === 0}
        noOptionsMessage={() => t('form.pointsGiveMinimum.errors.divisibleBy5')}
        createOptionPosition='first'
        id='appreciation-points'
        instanceId='appreciation-points'
      />
    </>
  )
}

CreateAppreciationPoints.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {}
}

export default CreateAppreciationPoints
