import React from 'react'
import Tooltip from 'src/components/atoms/Tooltip'
import styled from 'styled-components'
import { isNotNilOrEmpty } from 'src/utils/ramda'
import { capitalizeFirst } from 'src/utils/string'
import { propOr } from 'ramda'

export const UserMention = props => {
  const { user } = props

  const nickname = propOr('', 'nickname', user)
  const firstName = propOr('', 'firstName', user)
  const lastName = propOr('', 'lastName', user)

  const addSpaceIfExists = str => isNotNilOrEmpty(str) ? ' ' : ''
  const addDashIfExists = str => isNotNilOrEmpty(str) ? '- ' : ''

  const displayedName = isNotNilOrEmpty(nickname) ? nickname : firstName
  const tooltipName = `${capitalizeFirst(firstName)}${addSpaceIfExists(firstName)}${capitalizeFirst(lastName)}${addSpaceIfExists(lastName)}${addDashIfExists(nickname)}${nickname}`

  return (
    <Tooltip title={tooltipName} arrow placement='top'>
      <AppreciationUserMention>@{capitalizeFirst(displayedName)}&nbsp;</AppreciationUserMention>
    </Tooltip>
  )
}

export default UserMention

const AppreciationUserMention = styled.span`
  color: ${props => props.theme.palette.primary.main};
  cursor: pointer;
`
