import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { pipe, propOr, append, join } from 'ramda'
import { isNotNilOrEmpty } from 'src/utils/ramda'
import { appreciationFormFields } from 'src/utils/appreciations'

import { getHasPointSystem } from 'src/features/organisation/duck/selectors'

import AssignCoreValues from 'src/features/appreciations/components/CreateAppreciationInput/components/AssignCoreValues'
import CreateAppreciationContent from 'src/features/appreciations/components/CreateAppreciationInput/components/CreateAppreciationContent'
import CreateAppreciationReceivers from 'src/features/appreciations/components/CreateAppreciationInput/components/CreateAppreciationReceivers'
import CreateAppreciationPoints from 'src/features/appreciations/components/CreateAppreciationInput/components/CreateAppreciationPoints'
import CreateAppreciationIcon from 'src/features/appreciations/components/CreateAppreciationInput/components/CreateAppreciationIcon'

const pointSystemEmptyValue = {
  points: 0,
  receivers: [],
  content: '',
  coreValues: []
}

const nonPointSystemEmptyValue = {
  receivers: [],
  content: '',
  coreValues: []
}

export const CreateAppreciationInput = props => {
  const {
    onChange,
    name,
    reset,
    selectedEmojiObj,
    setFocusedFieldName,
    focusedFieldName
  } = props

  // REDUX
  const hasPointSystem = useSelector(getHasPointSystem)

  const initialValues = hasPointSystem
    ? pointSystemEmptyValue
    : nonPointSystemEmptyValue
  const [value, _setValue] = useState(initialValues)

  const { t } = useTranslation()

  useEffect(() => {
    if (reset) {
      _setValue(initialValues)
    }
  }, [reset])

  useEffect(() => {
    onChange(name, value)
  }, [value])

  useEffect(() => {
    handleAddEmoji(selectedEmojiObj.emoji)
  }, [selectedEmojiObj])

  const handleChangeValue = (name, newValue) =>
    _setValue({ ...value, [name]: newValue })
  const handleFocus = name => setFocusedFieldName(name)
  const handleBlur = name => setFocusedFieldName('')

  const handleAddEmoji = emoji => {
    const newContent = pipe(
      propOr('', appreciationFormFields.content),
      append(emoji),
      join('')
    )(value)

    _setValue({ ...value, content: newContent })
  }

  return (
    <>
      <InputContainer hasReceiverSelected={isNotNilOrEmpty(value.receivers)}>
        <CreateAppreciationIcon focusedFieldName={focusedFieldName} />
        {hasPointSystem && (
          <CreateAppreciationPoints
            name={appreciationFormFields.points}
            onChange={handleChangeValue}
            onFocus={handleFocus}
            onBlur={handleBlur}
            reset={reset}
          />
        )}
        <CreateAppreciationReceivers
          name={appreciationFormFields.receivers}
          pointsValue={value.points}
          onChange={handleChangeValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          reset={reset}
        />
        <CreateAppreciationContent
          name={appreciationFormFields.content}
          value={value.content}
          onChange={handleChangeValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={t('appreciations.reason')}
          reset={reset}
        />
        <CoreValuesContainer className='assign-core-values__hide-mobile'>
          <AssignCoreValues
            name={appreciationFormFields.coreValues}
            selectedValues={value.coreValues}
            onChange={handleChangeValue}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={t('appreciations.coreValues')}
          />
        </CoreValuesContainer>
      </InputContainer>
      <CoreValuesContainer className='assign-core-values__hide-desktop assign-core-values__core-values'>
        <AssignCoreValues
          name={appreciationFormFields.coreValues}
          selectedValues={value.coreValues}
          onChange={handleChangeValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={t('appreciations.coreValues')}
        />
      </CoreValuesContainer>
    </>
  )
}

CreateAppreciationInput.defaultProps = {
  onChange: () => {},
  validate: () => {},
  selectedEmoji: ''
}

export default CreateAppreciationInput

const InputContainer = styled.div`
  box-sizing: border-box;
  min-height: 56px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 2px solid ${props => props.theme.palette.common.alto};
  border-radius: 2px;
  padding: 7px 12px;
  font-size: 15px;

  &:focus-within {
    border: 2px solid ${props => props.theme.palette.primary.main};
  }

  .appreciation-input__separator {
    white-space: nowrap;
    color: ${props => props.theme.palette.common.slateGray};
  }

  .appreciation-input__separator--selected {
    color: ${props => props.theme.palette.text.main};
  }

  .appreciation-input__separator-at {
    display: ${props => (props.hasReceiverSelected ? 'none' : 'inline-block')};
  }

  ${props => props.theme.breakpointsMedia.mobile} {
    padding-bottom: 5px;
    font-size: 12px;
  }
`

const CoreValuesContainer = styled.div`
  &.assign-core-values__hide-desktop {
    display: none;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      display: block;
    }
  }
  
  &.assign-core-values__hide-mobile {
    display: block;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      display: none;
    }
  }
  
  &.assign-core-values__core-values {
    margin-top: 10px;
  }
`
