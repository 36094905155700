import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { head, map, pipe, split } from 'ramda';
import { FORMATS, formatDate } from 'src/utils/date'

import {
  deleteInvitationRoutine,
  getOrganisationInvitesRoutine
} from 'src/features/users/duck/actions'
import {
  getOrganisationInvites,
  getOrganisationInvitesMeta
} from 'src/features/users/duck/selectors'

import {
  Table,
  TableBody,
  TableCell,
  TableHeaderCell,
  TableHead,
  TableRow,
  TableFooter
} from 'src/components/atoms/Table/Table'
import Pagination from 'src/components/atoms/Table/Pagination'
import DeleteButtonWithConfirm from 'src/components/DeleteButtonWithConfirm'
import EmptyState from 'src/components/EmptyState'

export const InvitedUsers = props => {
  //  REDUX
  const dispatch = useDispatch()
  const organisationInvites = useSelector(getOrganisationInvites)
  const organisationInvitesPagination = useSelector(getOrganisationInvitesMeta)
  const fetchOrganisationInvites = useCallback(
    query => dispatch(getOrganisationInvitesRoutine(query)),
    [dispatch]
  )
  const deleteInvitation = useCallback(
    payload => dispatch(deleteInvitationRoutine(payload)),
    [dispatch]
  )

  const { t } = useTranslation()

  const getEmailPrefix = pipe(
    split('@'),
    head
  )

  const UserRow = user => (
    <TableRow key={user.id}>
      <TableCell>{user.email}</TableCell>
      <TableCell>{t(`userRoles.${user.role}`)}</TableCell>
      <TableCell>{formatDate(user.createdAt, FORMATS.slash)}</TableCell>
      <TableCell>
        <DeleteButtonWithConfirm
          text={t('manageUsersPage.invite.remove.confirmation')}
          submitText={t('manageUsersPage.invite.remove.submit')}
          cancelText={t('manageUsersPage.invite.remove.cancel')}
          id={user.id}
          onSubmit={deleteInvitation}
          buttonIdPrefix={getEmailPrefix(user.email)}
        />
      </TableCell>
    </TableRow>
  )

  const TableEmptyState = (
    <TableRow>
      <TableCell colSpan={4}>
        <EmptyStateContainer>
          <EmptyState text={t('manageUsersPage.invite.empty')} />
        </EmptyStateContainer>
      </TableCell>
    </TableRow>
  )

  const renderRows = map(UserRow)(organisationInvites)

  return (
    <TableContainer>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableHeaderCell>
              {t('manageUsersPage.invite.tableHeaders.email')}
            </TableHeaderCell>
            <TableHeaderCell>
              {t('manageUsersPage.invite.tableHeaders.role')}
            </TableHeaderCell>
            <TableHeaderCell>
              {t('manageUsersPage.invite.tableHeaders.createdAt')}
            </TableHeaderCell>
            <TableHeaderCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {organisationInvites.length > 0 ? renderRows : TableEmptyState}
        </TableBody>
        <TableFooter>
          <TableRow>
            <Pagination
              pagination={organisationInvitesPagination}
              fetchRecords={fetchOrganisationInvites}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default InvitedUsers

const EmptyStateContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TableContainer = styled.div`
  display: inline-block;
  width: 100%;
  overflow-x: auto;
`
