import { API_STATES } from 'src/utils/api'
import { propEq, propOr, pipe } from 'ramda'
import { createSelector } from 'reselect'
import { transformToArray } from 'src/utils/redux'

export const selectUsers = state => state.users

export const isUsersLoading = createSelector(
  selectUsers,
  propEq('state', API_STATES.IN_PROGRESS)
)

export const getOrganisationInvites = createSelector(
  selectUsers,
  pipe(propOr({}, 'organisationInvites'), transformToArray)
)

export const getOrganisationUsers = createSelector(
  selectUsers,
  pipe(propOr({}, 'users'), transformToArray)
)

export const getOrganisationUsersMeta = createSelector(
  selectUsers,
  propOr({}, 'metaUsers')
)

export const getOrganisationInvitesMeta = createSelector(
  selectUsers,
  propOr({}, 'metaOrganisationInvites')
)
