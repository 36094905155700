import React, { useEffect } from 'react'
import InfiniteScroller from 'react-infinite-scroller'
import styled from 'styled-components'
import qs from 'qs'

import Loader from 'src/components/atoms/Loader'

const recordsPerPage = 5

// InfiniteScroll

export const InfiniteScroll = props => {
  const {
    additionalQuery,
    fetchRecords,
    data,
    pagination,
    component: Component
  } = props

  const currentPage = Math.floor(data.length / recordsPerPage)

  useEffect(() => {
    handleFetchRecords()
  }, [])

  const canFetchData = () =>
    Number(data.length) < Number(pagination.recordsTotal)

  const handleFetchRecords = () => {
    fetchRecords(
      qs.stringify({
        limit: {
          page: currentPage + 1,
          take: recordsPerPage
        },
        ...additionalQuery
      })
    )
  }

  const showRecords = data.map((data, index) => (
    <Component data={data} key={index} />
  ))

  const scrollParentProps = props.scrollContainerRef
    ? {
        useWindow: false,
        getScrollParent: () => props.scrollContainerRef.current
      }
    : {}

  return (
    <>
      <InfiniteScroller
        {...scrollParentProps}
        page={currentPage}
        loadMore={handleFetchRecords}
        hasMore={canFetchData()}
        initialLoad={false}
      >
        {showRecords}
      </InfiniteScroller>
      {canFetchData() && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
    </>
  )
}

InfiniteScroll.defaultProps = {
  fetchRecords: data => console.log({ fetchRecords: data }),
  data: [],
  additionalQuery: {}
}

export default InfiniteScroll

const LoaderContainer = styled.div`
  text-align: center;
`
