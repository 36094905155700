import React, { useState } from 'react'
import styled from 'styled-components'

import Popover from 'src/components/atoms/Popover'
import IconButton from 'src/components/atoms/IconButton'
import TrashIcon from 'src/components/icons/Trash'
import Button from 'src/components/atoms/Button'

export const DeleteButtonWithConfirm = props => {
  const { id, onSubmit, buttonIdPrefix, canDelete } = props
  const [anchor, setAnchor] = useState(null)
  const open = Boolean(anchor)
  const componentId = open ? id : undefined

  const handleOpen = e => setAnchor(e.currentTarget)
  const handleClose = () => setAnchor(null)

  const handleSubmit = () => onSubmit(id)

  const ConfirmationPopup = (
    <Popover
      id={componentId}
      open={open}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <ConfirmationContainer>
        {props.text}
        <ConfirmationButtonsContainer>
          <Button
            onClick={handleSubmit}
            size='small'
            variant='outlined'
            id={`submit-delete-${buttonIdPrefix}`}
          >
            {props.submitText}
          </Button>
          <Button
            onClick={handleClose}
            size='small'
            variant='outlined'
            color='secondary'
            id={`cancel-delete-${buttonIdPrefix}`}
          >
            {props.cancelText}
          </Button>
        </ConfirmationButtonsContainer>
      </ConfirmationContainer>
    </Popover>
  )

  return (
    <>
      <IconButton
        id={`delete-${buttonIdPrefix}`}
        aria-describedby={componentId}
        onClick={handleOpen}
        disabled={!canDelete}
      >
        <TrashIcon />
      </IconButton>
      {ConfirmationPopup}
    </>
  )
}

export default DeleteButtonWithConfirm

export const ConfirmationContainer = styled.div`
  padding: 20px;
`

export const ConfirmationButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`
