import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { propOr } from 'ramda'
import moment from 'moment'
import { formOptionsOfMonthsBetweenDates } from 'src/utils/date'

import { getOrganisationDetails } from 'src/features/organisation/duck/selectors'

import Select from 'src/components/atoms/Select'

export const SelectReportMonth = props => {
  const { onChange, value } = props

  //  REDUX
  const organisation = useSelector(getOrganisationDetails)

  const { t } = useTranslation()
  const organisationCreatedAtDate = propOr('', 'createdAt', organisation)

  const options = formOptionsOfMonthsBetweenDates(
    organisationCreatedAtDate,
    moment().format()
  )

  const handleOnChange = (name, value) => onChange(value)

  return (
    <Select
      value={value}
      options={options}
      name='selectMonth'
      label={t('reportsPage.selectMonth')}
      onChange={handleOnChange}
    />
  )
}

export default SelectReportMonth
