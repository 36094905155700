import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { isNotNilOrEmpty } from 'src/utils/ramda'
import AnimatedDots from 'src/components/AnimatedDots'
import { useTranslation } from 'react-i18next'
import { appreciationFormFields } from 'src/utils/appreciations'
import { Emoji } from 'emoji-mart'
import DeviceDetector from 'src/components/DeviceDetector'

import { getHasAnyCoreValues } from 'src/features/coreValues/duck/selectors'

export const CreateAppreciationEncourageText = props => {
  const { focusedFieldName } = props
  const { t } = useTranslation()
  const hasAnyCoreValues = useSelector(getHasAnyCoreValues)

  const GetEmojiByName = name => (
    <>
      <div className='web-inline'>
        <Emoji
          set='apple'
          emoji={name}
          size={25}
          fallback={emoji => {
            return emoji ? `:${emoji.short_names[0]}:` : name
          }}
        />
      </div>
      <div className='mobile-inline'>
        <Emoji
          set='apple'
          emoji={name}
          size={17}
          fallback={emoji => {
            return emoji ? `:${emoji.short_names[0]}:` : name
          }}
        />
      </div>
    </>
  )

  const setEmoji = () => {
    switch (true) {
      case focusedFieldName === appreciationFormFields.content:
        return GetEmojiByName('tada')
      case focusedFieldName === appreciationFormFields.points:
        return GetEmojiByName('rocket')
      case focusedFieldName === appreciationFormFields.receivers:
        return GetEmojiByName('pray')
      default:
        return ''
    }
  }

  const getContentTranslationPath =
    focusedFieldName === appreciationFormFields.content && hasAnyCoreValues
      ? 'coreValues' : focusedFieldName

  return (
    <CreateAppreciationEncourageTextContainer>
      <DeviceDetector>
        {isNotNilOrEmpty(focusedFieldName) ? (
          <>
          <span className='encourage-text__content'>
            {t(`appreciations.encourage.${getContentTranslationPath}`)}
          </span>
            <span className='encourage-text__emoji'>&nbsp;{setEmoji()}</span>
            <AnimatedDots />
          </>
        ) : (
          <>
          <span className='encourage-text__content encourage-text__mobile'>
            {t(`appreciations.encourage.default`)}
          </span>
            <span className='encourage-text__emoji encourage-text__mobile'>
            &nbsp;{GetEmojiByName('heart')}
          </span>
          </>
        )}
      </DeviceDetector>
    </CreateAppreciationEncourageTextContainer>
  )
}

export default CreateAppreciationEncourageText

const CreateAppreciationEncourageTextContainer = styled.div`
  color: ${props => props.theme.palette.common.gray};
  line-height: 16px;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    line-height: 12px;
    font-size: 12px;
    max-width: 130px;
  }

  .encourage-text__emoji {
    line-height: 1;
    position: relative;
    top: 5px;
  }
  
  .encourage-text__content {
    max-width: 130px;
  }
  
  .encourage-text__mobile {
    ${props => props.theme.breakpointsMedia.mobile} {
      display: none;
    }
  }
`
