import React, { useEffect, useState } from 'react';

import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

export const Toggle = props => {
  const { value: initialValue, onChange, name, label, disabled } = props
  const [checked, setChecked] = useState(initialValue || false)

  useEffect(() => {
    setChecked(initialValue)
  }, [initialValue])

  const handleChange = e => {
    setChecked(e.target.checked)
    onChange(name, e.target.checked)
  }

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            disabled={disabled}
            checked={checked}
            onChange={handleChange}
            value={checked}
            color='primary'
            name={name}
            id={name}
          />
        }
        label={label}
      />
    </FormGroup>
  )
}

Toggle.defaultProps = {
  onChange: () => {},
  validate: () => {}
}

export default Toggle
