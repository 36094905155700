import React from 'react'
import { pathOr } from 'ramda'
import styled from 'styled-components'

const CoreValue = props => {

  return (
    <CoreValueContainer>
      <span>#{props.name}</span>
    </CoreValueContainer>
  )
}

export const SelectedCoreValues = (props) => {
  const { values } = props

  const selectedCoreValues = pathOr([], ['appreciation', 'coreValues'], values)

  return selectedCoreValues.length > 0
    && (
      <CoreValuesContainer>
        {
          selectedCoreValues.map(val =>
            <CoreValue key={`selected-core-value-${val}`} name={val} />)
        }
      </CoreValuesContainer>
    )
}

export default SelectedCoreValues

const CoreValuesContainer = styled.div`
  display: flex;
  margin-top: 10px;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    display: none;
  }
`

const CoreValueContainer = styled.div`
  line-height: 30px;
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer;
  color: ${props => props.theme.palette.primary.main};
`
