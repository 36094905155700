import React, { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { isNotNilOrEmpty } from 'src/utils/ramda'

import { showToastRoutine } from 'src/features/toast/duck/actions'
import {
  createReactionToAppreciationRoutine,
  removeReactionFromAppreciationRoutine
} from 'src/features/appreciations/duck/actions'
import { getHasPointSystem } from 'src/features/organisation/duck/selectors'
import {
  getReactionCountByAppreciationIdAndReactionContent,
  getReactionGiverNamesByAppreciationIdAndReactionContent,
  getSelectedReactionId,
  getIsAppreciationFromPreviousMonth,
  isReactionSelectedByUser
} from 'src/features/appreciations/duck/selectors'

import { Emoji } from 'emoji-mart'
import { SEVERITY } from 'src/utils/toast'
import Tooltip from 'src/components/atoms/Tooltip'

const ReactionEmoji = props => (
  <Emoji
    set='apple'
    emoji={props.emoji}
    size={17}
    fallback={(emoji, props) => {
      return emoji ? `:${emoji.short_names[0]}:` : props.emoji
    }}
  />
)

const ReactionCount = props =>
  props.count > 0 && <div className='reaction__count'>{props.count}</div>

export const Reaction = memo(props => {
  const { reaction, appreciationId } = props

  // REDUX
  const dispatch = useDispatch()
  const hasPointSystem = useSelector(getHasPointSystem)
  const reactionCount = useSelector(state =>
    getReactionCountByAppreciationIdAndReactionContent(
      state,
      appreciationId,
      reaction.content
    )
  )
  const isSelectedByUser = useSelector(state =>
    isReactionSelectedByUser(state, appreciationId, reaction.content)
  )
  const selectedReactionId = useSelector(state =>
    getSelectedReactionId(state, appreciationId, reaction.content)
  )
  const reactionGivers = useSelector(state =>
    getReactionGiverNamesByAppreciationIdAndReactionContent(
      state,
      appreciationId,
      reaction.content
    )
  )
  const isAppreciationFromPreviousMonth = useSelector(state =>
    getIsAppreciationFromPreviousMonth(state, appreciationId)
  )

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )
  const createReaction = useCallback(
    () =>
      dispatch(
        createReactionToAppreciationRoutine({
          appreciationId: appreciationId,
          content: reaction.content
        })
      ),
    [dispatch]
  )
  const deleteReaction = useCallback(
    id =>
      dispatch(
        removeReactionFromAppreciationRoutine({
          appreciationId: appreciationId,
          id
        })
      ),
    [dispatch]
  )

  const ReactionContent = hasPointSystem ? (
    <>
      <div className='reaction__points'>+{reaction.points}</div>
      <ReactionEmoji emoji={reaction.content} />
      <ReactionCount count={reactionCount} />
    </>
  ) : (
    <>
      <ReactionEmoji emoji={reaction.content} />
      <div className='reaction__label'>{reaction.label}</div>
      <ReactionCount count={reactionCount} />
    </>
  )

  const handleDeleteReaction = () => {
    isAppreciationFromPreviousMonth
      ? showToast({
          key: 'toast.deleteReactionFromPreviousMonth',
          severity: SEVERITY.error
        })
      : deleteReaction(selectedReactionId)
  }

  const handleReactionClick = () =>
    isSelectedByUser ? handleDeleteReaction() : createReaction()

  const getGivers = reactionGivers.join(', ')
  const Reaction = (
    <ReactionContainer
      selected={isSelectedByUser}
      onClick={handleReactionClick}
    >
      {ReactionContent}
    </ReactionContainer>
  )

  return isNotNilOrEmpty(reactionGivers) ? (
    <Tooltip title={getGivers}>{Reaction}</Tooltip>
  ) : (
    Reaction
  )
})

Reaction.defaultProps = {
  appreciationId: '',
  reaction: {
    points: 0,
    label: '',
    content: ''
  }
}

export default Reaction

const ReactionContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props =>
    props.selected
      ? props.theme.palette.primary.main
      : props.theme.palette.common.alto};
  background-color: ${props =>
    props.selected
      ? props.theme.palette.primary.transparent
      : props.theme.palette.common.white};
  padding: 5px 10px;
  font-size: 12px;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-top: 10px;

  ${props => props.theme.breakpointsMedia.mobile} {
    padding: 3px 5px;
    margin-right: 5px;
  }

  .reaction__points {
    display: inline-block;
    margin-right: 5px;
  }

  .reaction__label {
    display: inline-block;
    margin-left: 5px;
    white-space: nowrap;
  }

  .reaction__count {
    color: ${props => props.theme.palette.primary.main};
    font-weight: 700;
    margin-left: 5px;
    font-size: 11px;
  }

  &:hover {
    background-color: ${props => props.theme.palette.common.alto};
  }

  &:focus,
  &:active {
    border-color: ${props => props.theme.palette.primary.dark};
    background-color: ${props =>
      props.theme.palette.primary.transparent};
  }
`
