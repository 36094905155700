import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { pathOr, omit } from 'ramda'
import {
  validateOrganisationSettingsField,
  validateOrganisationSettingsValues
} from 'src/features/organisation/duck/schema'

import { updateOrganisationSettingsRoutine } from 'src/features/organisation/duck/actions'
import {
  getOrganisationDetails,
  isOrganisationLoading
} from 'src/features/organisation/duck/selectors'

import Form from 'src/components/atoms/Form'
import Toggle from 'src/components/atoms/Toggle'
import Button from 'src/components/atoms/Button'
import Input from 'src/components/atoms/Input'

const emptyValues = {
  pointsEnabled: false,
  pointsAmount: 0,
  pointsGiveMinimum: 0,
  pointsNamePlural: 'points'
}

export const OrganisationSettings = props => {
  //  REDUX
  const dispatch = useDispatch()
  const isLoading = useSelector(isOrganisationLoading)
  const organisation = useSelector(getOrganisationDetails)
  const updateOrganisationSettings = useCallback(
    payload => dispatch(updateOrganisationSettingsRoutine(payload)),
    [dispatch]
  )

  const { t } = useTranslation()
  const [values, setValues] = useState(emptyValues)
  const [valid, _validateSchema] = useState(true)
  const [submitting, setIsSubmitting] = useState(false)
  const pointsEnabled = pathOr(
    false,
    ['settings', 'pointsEnabled'],
    organisation
  )
  const pointsAmount = pathOr(0, ['settings', 'pointsAmount'], organisation)
  const pointsNameSingular = pathOr('point', ['settings', 'pointsNameSingular'], organisation)
  const pointsNamePlural = pathOr('points', ['settings', 'pointsNamePlural'], organisation)
  const pointsGiveMinimum = pathOr(
    '',
    ['settings', 'pointsGiveMinimum'],
    organisation
  )

  useEffect(() => {
    isLoading === false && setIsSubmitting(false)
  }, [isLoading])

  useEffect(() => {
    setInitialValues()
  }, [organisation])

  const setInitialValues = () =>
    setValues({
      pointsEnabled,
      pointsAmount,
      pointsGiveMinimum,
      pointsNamePlural
    })

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  useEffect(() => {
    validateOrganisationSettingsValues(values, _validateSchema)
  }, [values])

  const handleSubmit = async e => {
    e.preventDefault()
    const payload = values.pointsEnabled
      ? values
      : omit(
          [
            'pointsAmount',
            'pointsGiveMinimum'
          ],
          values
        )
    setIsSubmitting(true)
    updateOrganisationSettings(payload)
  }

  const PointSystemFields = (
    <>
      <Input
        value={pointsAmount}
        required
        name='pointsAmount'
        type='number'
        label={t('form.pointsAmount.label')}
        onChange={handleOnChange}
        validate={validateOrganisationSettingsField(values)}
      />
      <Input
        value={pointsGiveMinimum}
        required
        name='pointsGiveMinimum'
        type='number'
        label={t('form.pointsGiveMinimum.label')}
        onChange={handleOnChange}
        validate={validateOrganisationSettingsField(values)}
      />
      <Input
        value={pointsNamePlural}
        required
        name='pointsNamePlural'
        type='text'
        label={t('form.pointsNamePlural.label')}
        onChange={handleOnChange}
        validate={validateOrganisationSettingsField(values)}
      />
    </>
  )

  return (
    <Form onSubmit={handleSubmit}>
      <Toggle
        value={pointsEnabled}
        name='pointsEnabled'
        label={t('form.pointsEnabled.label')}
        onChange={handleOnChange}
      />
      {values.pointsEnabled && PointSystemFields}
      <ButtonContainer>
        <Button
          type='submit'
          disabled={(isLoading && submitting) || !valid}
          onClick={handleSubmit}
          variant='contained'
          color='primary'
          id='submit-point-system'
        >
          {t('myAccountPage.updateCTA')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

export default OrganisationSettings

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
