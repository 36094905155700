import styled from 'styled-components'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'

export const TableHeaderCell = styled(TableCell)`
  word-break: keep-all;
`

export { Table, TableBody, TableCell, TableHead, TableRow, TableFooter }
