import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  validateCoreValueField,
  validateCoreValueValues
} from 'src/features/coreValues/duck/schema'

import { updateCoreValueRoutine } from 'src/features/coreValues/duck/actions'
import { isCoreValuesLoading } from 'src/features/coreValues/duck/selectors'

import Form from 'src/components/atoms/Form'
import Button from 'src/components/atoms/Button'
import Input from 'src/components/atoms/Input'
import { propOr } from 'ramda'

const emptyValues = {
  coreValueName: '',
  id: ''
}

export const EditCoreValueForm = props => {
  //  REDUX
  const { data, onSubmit } = props
  const dispatch = useDispatch()
  const isLoading = useSelector(isCoreValuesLoading)
  const editCoreValue = useCallback(
    payload => dispatch(updateCoreValueRoutine(payload)),
    [dispatch]
  )

  const { t } = useTranslation()
  const [values, setValues] = useState(emptyValues)
  const [valid, _validateSchema] = useState(true)
  const [submitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    setValues({
      ...values,
      coreValueName: propOr('', 'name', data),
      id: propOr('', 'id', data)
    })
  }, [data])

  useEffect(() => {
    isLoading === false && setIsSubmitting(false)
  }, [isLoading])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  useEffect(() => {
    validateCoreValueValues(values, _validateSchema)
  }, [values])

  const handleSubmit = async e => {
    e.preventDefault()
    setIsSubmitting(true)
    editCoreValue({
      values: {
        name: values.coreValueName,
        id: values.id
      },
      callback: onSubmit
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        value={propOr('', 'name', data)}
        required
        name='coreValueName'
        type='text'
        label={t('form.coreValue.label')}
        onChange={handleOnChange}
        validate={validateCoreValueField(values)}
      />
      <ButtonContainer>
        <Button
          type='submit'
          disabled={(isLoading && submitting) || !valid}
          onClick={handleSubmit}
          variant='contained'
          color='primary'
        >
          {t('myAccountPage.updateCTA')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

export default EditCoreValueForm

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
