import React from 'react'
import styled from 'styled-components'
import Paper from 'src/components/atoms/Paper'

import HiPanda from 'src/assets/images/illustrations/panda1.png'
import { useTranslation } from 'react-i18next'

export const AppreciationsEmptyState = props => {
  const { t } = useTranslation()

  return (
    <StyledPaper>
      <img className='empty-state__image' src={HiPanda} alt='welcome-panda' />
      <div className='empty-state__text'>
        {t('emptyStates.appreciationsList')}
      </div>
    </StyledPaper>
  )
}

export default AppreciationsEmptyState

const StyledPaper = styled(Paper)`
  box-sizing: border-box;
  text-align: center;
  padding: 30px 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .empty-state__image {
    max-width: 100px;
  }

  .empty-state__text {
    color: ${props => props.theme.palette.text.main};
    font-weight: 500;
    font-size: 14px;
    margin-top: 10px;
  }
`
