import { document, getSelection } from 'src/utils/window'

export const copyElementToClipboardById = id => {
  const selectedElement = document.getElementById(id)
  let range, selection
  if (document.createRange && getSelection) {
    range = document.createRange()
    selection = getSelection()
    selection.removeAllRanges()
    document.execCommand('copy')
    try {
      range.selectNodeContents(selectedElement)
      selection.addRange(range)
      document.execCommand('copy')
    } catch (e) {
      range.selectNode(selectedElement)
      selection.addRange(range)
      document.execCommand('copy')
    }
  } else if (document.body.createTextRange) {
    range = document.body.createTextRange()
    range.moveToElementText(selectedElement)
    range.select()
    document.execCommand('copy')
  }
}
