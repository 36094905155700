import { string, object, boolean, number, ref } from 'yup'
import { validateValues, validateField } from 'src/utils/form'

// Organisation Details Schema

export const organisationDetailsSchema = object().shape({
  name: string().required(() => ({
    key: 'form.organisationName.errors.required'
  })),
  country: string().required(() => ({
    key: 'form.country.errors.required'
  }))
})

export const validateOrganisationDetailsField = values =>
  validateField(organisationDetailsSchema, values)
export const validateOrganisationDetailsValues = validateValues(
  organisationDetailsSchema
)

// Organisation Settings Schema

export const organisationSettingsSchema = object().shape({
  pointsEnabled: boolean().required(() => ({
    key: 'form.pointsEnabled.errors.required'
  })),
  pointsNamePlural: string().when('pointsEnabled', {
    is: true,
    then: string()
      .required(() => ({
        key: 'form.pointsNamePlural.errors.required'
      }))
      .test(
        'is-proper-length',
        () => ({ key: 'form.pointsNamePlural.errors.length' }),
        value => value.length < 15
      )
  }),
  pointsAmount: number().when('pointsEnabled', {
    is: true,
    then: number()
      .required(() => ({
        key: 'form.pointsAmount.errors.required'
      }))
      .test(
        'is-divisible-by-5',
        () => ({ key: 'form.pointsAmount.errors.divisibleBy5' }),
        value => value % 5 === 0
      )
  }),
  pointsGiveMinimum: number().when('pointsEnabled', {
    is: true,
    then: number()
      .required(() => ({
        key: 'form.pointsGiveMinimum.errors.required'
      }))
      .lessThan(ref('pointsAmount'), () => ({ key: 'form.pointsGiveMinimum.errors.moreThanMaxPointsAmount' }))
      .test(
        'is-divisible-by-5',
        () => ({ key: 'form.pointsGiveMinimum.errors.divisibleBy5' }),
        value => value % 5 === 0
      )
  })
})

export const validateOrganisationSettingsField = values =>
  validateField(organisationSettingsSchema, values)
export const validateOrganisationSettingsValues = validateValues(
  organisationSettingsSchema
)
