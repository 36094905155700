import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Paper from 'src/components/atoms/Paper'
import AuthPageTitle from 'src/components/atoms/AuthPageTitle'
import InviteUsers from 'src/features/users/components/InviteUsers'
import InvitedUsers from 'src/features/users/components/InvitedUsers'
import UsersList from 'src/features/users/components/UsersList'
import Grid from 'src/components/atoms/Grid'
import Metadata from 'src/components/Metadata'
import { META } from 'src/utils/seo'
import PaddingContainer from 'src/components/PaddingContainer'

import Tabs from 'src/components/atoms/Tabs'
import Tab from 'src/components/atoms/Tab'

export const ManageUsers = props => {
  const { t } = useTranslation()
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => setValue(newValue)

  const TableTabs = (
    <Tabs
      indicatorColor='primary'
      textColor='primary'
      variant='fullWidth'
      value={value}
      onChange={handleChange}
    >
      <Tab
        id='tab-manage-users'
        value={0}
        wrapped
        label={t('manageUsersPage.manageUsers.sectionTitle')}
      />
      <Tab id='tab-invite-users' value={1} wrapped label={t('manageUsersPage.invite.sectionTitle')} />
    </Tabs>
  )

  const InvitesSection = (
    <>
      <InviteUsers />
      <InvitedUsers />
    </>
  )

  const ActiveSection = value === 1 ? InvitesSection : <UsersList />

  return (
    <PaddingContainer>
      <Metadata meta={META.manageUsers} />
      <AuthPageTitle>{t('manageUsersPage.pageTitle')}</AuthPageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <StyledPaper>
            {TableTabs}
            <SectionsContainer>{ActiveSection}</SectionsContainer>
          </StyledPaper>
        </Grid>
      </Grid>
    </PaddingContainer>
  )
}

export default ManageUsers

export const SectionsContainer = styled.div`
  margin-top: 20px;
`

export const StyledPaper = styled(Paper)`
  box-sizing: border-box;
  width: 100%;
`
