import { string, object, array } from 'yup'
import { validateValues } from 'src/utils/form'

// Create Non Point Appreciation form validator

export const nonPointAppreciationSchema = object().shape({
  appreciation: object().shape({
    content: string().required(),
    receivers: array().required(),
    coreValues: array()
  })
})

export const validateNonPointAppreciationValues = validateValues(
  nonPointAppreciationSchema
)

// Create Point Appreciation form validator

export const pointAppreciationSchema = object().shape({
  appreciation: object().shape({
    content: string().required(),
    receivers: array().required(),
    points: string().required(),
    coreValues: array()
  })
})

export const validatePointAppreciationValues = validateValues(
  pointAppreciationSchema
)
