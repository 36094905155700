import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import PATHS from 'src/utils/paths'

import { getIsAdminUser } from 'src/features/auth/duck/selectors'

import {
  SectionContainer,
  SectionTitleContainer,
  SectionTitle,
  SectionSubtitleContainer,
  SectionSubtitle
} from 'src/features/auth/components/AuthRightSideBar/styled'
import AuthSideBarRecentlyThankedList from 'src/features/auth/components/AuthRightSideBar/AuthRightSideBaRecentlyThankedList'
import AuthSideBarLeadershipList from 'src/features/auth/components/AuthRightSideBar/AuthRightSideBarLeadershipList'

export const AuthRightSideBarRankingSection = props => {
  //  REDUX
  const isAdminUser = useSelector(getIsAdminUser)

  const { t } = useTranslation()

  return (
    <StyledSectionContainer>
      <SectionTitleContainer>
        <SectionTitle>
          {t(`authSideBar.ranking.${isAdminUser ? 'adminTitle' : 'userTitle'}`)}
        </SectionTitle>
        {isAdminUser && (
          <Link to={PATHS.workspaceReports}>
            <LinkToReport>{t('authSideBar.ranking.viewAll')}</LinkToReport>
          </Link>
        )}
      </SectionTitleContainer>
      <SectionSubtitleContainer>
        <SectionSubtitle>
          {t(
            `authSideBar.ranking.${
              isAdminUser ? 'subTitleAdmin' : 'subTitleUser'
            }`
          )}
        </SectionSubtitle>
      </SectionSubtitleContainer>
      <RankingListContainer>
        {isAdminUser ? (
          <AuthSideBarLeadershipList />
        ) : (
          <AuthSideBarRecentlyThankedList />
        )}
      </RankingListContainer>
    </StyledSectionContainer>
  )
}

export default AuthRightSideBarRankingSection

const RankingListContainer = styled.div`
  flex-grow: 1;
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`

const StyledSectionContainer = styled(SectionContainer)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const LinkToReport = styled.div`
  color: ${props => props.theme.palette.primary.main};
  font-size: 12px;
  font-weight: 500;
`
