import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import eventEmitter from 'src/providers/eventEmitter'
import events from 'src/utils/events'
import PATHS from 'src/utils/paths'
import { SEVERITY } from 'src/utils/toast'
import withLocation from 'src/hoc/withLocation'

import {
  getIsAuthorised,
  getIsAuthUserFetched,
  getIsAuthUserActive
} from 'src/features/auth/duck/selectors'
import {
  fetchAuthUserRoutine,
  logoutUserRoutine
} from 'src/features/auth/duck/actions'
import { showToastRoutine } from 'src/features/toast/duck/actions'
import { getOrganisationDetailsRoutine } from 'src/features/organisation/duck/actions'

import { AuthNavigation } from 'src/components/Navigation/Navigation'
import AuthRightSideBar from 'src/features/auth/components/AuthRightSideBar/AuthRightSideBar'
import DeviceDetector from 'src/components/DeviceDetector'
import Arrow from 'src/components/icons/Arrow'

export const AuthLayout = props => {
  const { navigate, location } = props
  const { pathname } = location
  //  REDUX
  const dispatch = useDispatch()
  const isAuthorised = useSelector(getIsAuthorised)
  const isAuthUserActive = useSelector(getIsAuthUserActive)
  const isAuthUserFetched = useSelector(getIsAuthUserFetched)

  const [isSideBarOpen, setIsSideBarOpen] = React.useState(true)
  const toggleSideBar = () => setIsSideBarOpen(prevState => !prevState)

  const fetchAuthUser = useCallback(() => dispatch(fetchAuthUserRoutine()), [
    dispatch
  ])
  const getOrganisationDetails = useCallback(
    () => dispatch(getOrganisationDetailsRoutine()),
    [dispatch]
  )
  const logoutUser = useCallback(() => dispatch(logoutUserRoutine()), [
    dispatch
  ])
  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const [drawerVisible, setDrawerVisible] = useState(false)

  const logoutInactiveUser = () => {
    logoutUser()
    showToast({
      key: 'toast.accountInactive',
      severity: SEVERITY.error
    })
  }

  useEffect(() => {
    fetchAuthUser()
    getOrganisationDetails()
    !isAuthorised && navigate(PATHS.logIn)
    eventEmitter.on(events.logoutInactiveUser, logoutInactiveUser)

    return () => {
      eventEmitter.off(events.logoutInactiveUser)
    }
  }, [])

  useEffect(() => {
    if (pathname === PATHS.workspaceUsers) {
      setIsSideBarOpen(false)
    }
  }, [location])

  useEffect(() => {
    if (isAuthUserFetched && !isAuthUserActive) {
      logoutInactiveUser()
    }
  }, [isAuthUserFetched])

  const toggleDrawer = () => setDrawerVisible(prevVisibility => !prevVisibility)

  return (
    <AuthLayoutContainer>
      <AuthNavigation open={drawerVisible} toggleDrawer={toggleDrawer} />
      <AuthContent>{props.children}</AuthContent>
      <DeviceDetector>
        <SideBarContainer isSideBarOpen={isSideBarOpen} className='web'>
          <div onClick={toggleSideBar} className='toggle-sidebar__button'>
            <Arrow />
          </div>
          { isSideBarOpen && <AuthRightSideBar />}
        </SideBarContainer>
      </DeviceDetector>
    </AuthLayoutContainer>
  )
}

export default withLocation(AuthLayout)

const SideBarContainer = styled.div`
  .toggle-sidebar__button {
    position: fixed;
    top: 5px;
    right: ${props => props.isSideBarOpen ? `calc(${props.theme.dimensions.authRightSideBarWidth} - 13px)` : '13px'};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background-color: ${props => props.theme.palette.common.white};
    color: ${props => props.theme.palette.primary.main};
    box-shadow: ${props => props.theme.shadows.main};
    cursor: pointer;
    transform: ${props => props.isSideBarOpen ? 'rotate(90deg)' : 'rotate(270deg)'};
    z-index: 3000;
    transition: ${props =>
            `transform ${props.theme.transitions.duration.standard}ms ${props.theme.transitions.easing.easeInOut}`};

  }
`

const AuthLayoutContainer = styled.div`
  display: flex;
  min-height: 100vh;
  height: 100%;
  box-sizing: border-box;

  ${props => props.theme.breakpointsMedia.mobile} {
    flex-direction: column;
    min-height: calc(
      100vh - ${props => props.theme.dimensions.navHeight}
    );
  }
`

const AuthContent = styled.div`
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  background-color: ${props =>
    props.theme.palette.common.catskillWhite};
    
  ${props => props.theme.breakpointsMedia.mobile} {
    margin-top: ${props => props.theme.dimensions.navHeight};
  }
`
