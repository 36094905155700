import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { getCoreValuesList } from 'src/features/coreValues/duck/selectors'
import { fetchCoreValuesRoutine, deleteCoreValueRoutine } from 'src/features/coreValues/duck/actions'

import EmptyState from 'src/components/EmptyState'
import PaperTitle from 'src/components/atoms/PaperTitle'
import DeleteButtonWithConfirm from 'src/components/DeleteButtonWithConfirm'
import UpdateCoreValueModal from 'src/features/coreValues/components/UpdateCoreValueModal';

export const CoreValueItem = props => {
  const { data } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const deleteCoreValue = useCallback(
    payload => dispatch(deleteCoreValueRoutine(payload)),
    [dispatch]
  )

  return (
    <StyledCoreValueItem id={`core-value-list-item-${data.name}`}>
      <div>
        <Dot /> {data.name}
      </div>
      <div>
        <UpdateCoreValueModal data={data} />
        <DeleteButtonWithConfirm
          text={t('organisationSettingsPage.coreValues.remove.confirmation')}
          submitText={t('organisationSettingsPage.coreValues.remove.submit')}
          cancelText={t('organisationSettingsPage.coreValues.remove.cancel')}
          id={data.id}
          onSubmit={deleteCoreValue}
          buttonIdPrefix={data.name}
        />
      </div>
    </StyledCoreValueItem>
  )
}

export const CoreValuesList = (props) => {
  const dispatch = useDispatch()
  const coreValues = useSelector(getCoreValuesList)
  const { t } = useTranslation()

  const fetchCoreValues = useCallback(
    () => dispatch(fetchCoreValuesRoutine()),
    [dispatch]
  )

  useEffect(() => {
    fetchCoreValues()
  }, [])

  const renderCoreValues = coreValues.map(
    coreValue => <CoreValueItem key={coreValue.id} data={coreValue} />
  )

  const renderContent = coreValues.length > 0 ? renderCoreValues : (
    <EmptyState id='core-values-list-empty-state' text={t('organisationSettingsPage.coreValues.emptyState')} />
  )

  return (
    <>
      <PaperTitle>{t('organisationSettingsPage.coreValues.title')}</PaperTitle>
      {renderContent}
    </>
  )
}

export default CoreValuesList

const StyledCoreValueItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

const Dot = styled.div`
  display: inline-block;
  
  &::after {
    content: "#";
    color: ${props => props.theme.palette.primary.main};
    font-size: 17px;
  }
`
