import React from 'react'
import AuthLayout from 'src/theme/AuthLayout'
import { Router } from '@reach/router'
import PrivateRoute from 'src/components/PrivateRoute'
import PATHS from 'src/utils/paths'
import withPrivatePage from 'src/hoc/withPrivatePage'
import ManageUsers from 'src/features/users/ManageUsers'
import UsersReports from 'src/features/reports/UsersReports'
import UpdateProfile from 'src/features/auth/UpdateProfile'
import Metadata from 'src/components/Metadata'
import { META } from 'src/utils/seo'
import OrganisationSettings from 'src/features/organisation/OrganisationSettingsView'
import AppreciationsView from 'src/features/appreciations/AppreciationsView'
import AuthRightSideBar from 'src/features/auth/components/AuthRightSideBar/AuthRightSideBar'

export const Workspace = props => {
  return (
    <AuthLayout>
      <Metadata meta={META.workspace} />
      <Router>
        <PrivateRoute
          path={PATHS.workspaceDashboard}
          component={AppreciationsView}
        />
        <PrivateRoute
          restricted
          path={PATHS.workspaceUsers}
          component={ManageUsers}
        />
        <PrivateRoute
          restricted
          path={PATHS.workspaceReports}
          component={UsersReports}
        />
        <PrivateRoute
          restricted
          path={PATHS.organisationSettings}
          component={OrganisationSettings}
        />
        <PrivateRoute
          path={PATHS.workspaceMyAccount}
          component={UpdateProfile}
        />
        <PrivateRoute
          path={PATHS.workspaceMyProfile}
          component={AuthRightSideBar}
        />
      </Router>
    </AuthLayout>
  )
}

export default withPrivatePage(Workspace)
