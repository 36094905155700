import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import PATHS from 'src/utils/paths'
import { META } from 'src/utils/seo'
import useScrollDetector from 'src/hooks/useScrollDetector'

import { fetchUserAppreciationCountersRoutine } from 'src/features/appreciations/duck/actions'
import {
  getAppreciationsGivenCount,
  getAppreciationsReceivedCount
} from 'src/features/appreciations/duck/selectors'
import { getIsAdminUser } from 'src/features/auth/duck/selectors'

import BubbleBackground from 'src/components/BubbleBackground'
import Metadata from 'src/components/Metadata'
import CreateAppreciation from 'src/features/appreciations/CreateApreciation'
import AppreciationsList from 'src/features/appreciations/components/AppreciationsList'
import GivenByAuthUserAppreciationsList from 'src/features/appreciations/components/GivenByAuthUserAppreciationsList'
import ReceivedByAuthUserAppreciationsList from 'src/features/appreciations/components/ReceivedByAuthUserAppreciationsList'
import TabLink from 'src/components/atoms/CategoryLink'
import AuthPageTitle from 'src/components/atoms/AuthPageTitle'
import PrimaryButton from 'src/components/atoms/Button'
import GreetingTitle from 'src/components/GreetingTitle'
import DeviceDetector from 'src/components/DeviceDetector'

import AddUser from 'src/components/icons/AddUser'
import ArrowIcon from 'src/components/icons/Arrow'
import MissingDataInfoMessage from 'src/features/auth/components/MissingDataInfoMessage';

export const AppreciationsView = props => {
  const hasScrolled = useScrollDetector(200)
  // REDUX
  const dispatch = useDispatch()
  const appreciationsGivenCount = useSelector(getAppreciationsGivenCount)
  const appreciationsReceivedCount = useSelector(getAppreciationsReceivedCount)
  const isAdminUser = useSelector(getIsAdminUser)
  const fetchUserAppreciationCounters = useCallback(
    payload => dispatch(fetchUserAppreciationCountersRoutine(payload)),
    [dispatch]
  )

  // STATE
  const [selectedTab, setSelectedTab] = useState(1)
  const [createFormMobileVisible, setCreateFormMobileVisibility] = useState(true)
  const scrollContainerRef = useRef(null)
  const { t } = useTranslation()

  useEffect(() => {
    fetchUserAppreciationCounters()
  }, [])

  const changeTab = number => () => setSelectedTab(number)
  const navigateToManageUsers = () => navigate(PATHS.workspaceUsers)

  const toggleCreateFormVisibilityOnMobile = () => setCreateFormMobileVisibility(prevState => !prevState)

  const AppreciationCategoryTabs = (
    <div className='appreciations-view__tabs'>
      <div className='appreciations-view__list-wrapper'>
        <TabLink onClick={changeTab(1)} selected={selectedTab === 1}>
          {t('dashboardPage.allAppreciations')}
        </TabLink>
        <TabLink onClick={changeTab(2)} selected={selectedTab === 2}>
          {t('dashboardPage.receivedAppreciations')}&nbsp;(
          {appreciationsReceivedCount})
        </TabLink>
        <TabLink onClick={changeTab(3)} selected={selectedTab === 3}>
          {t('dashboardPage.givenAppreciations')}&nbsp;(
          {appreciationsGivenCount})
        </TabLink>
      </div>
    </div>
  )

  const getFormHeight = () => {
    const el = document.getElementById('create-appreciation-form-scrolled')

    // "&& el.clientHeight" and "188" is to avoid showing opened form on the first scroll
    return el && el.clientHeight > 0 ? el.clientHeight : 188
  }

  const AppreciationCreateForm = id => (
    <div id={id} className='appreciations-view__create'>
      <CreateAppreciation />
    </div>
  )

  const FixedAppreciationTabsAndFormMobile = (
    <div className='appreciations-view__create-and-tabs'>
      {AppreciationCreateForm('create-appreciation-form-scrolled')}
      <div className='appreciations-view__form-visibility-toggle' onClick={toggleCreateFormVisibilityOnMobile}>
        {t('dashboardPage.mobileShowCreateForm')}
        <span className='appreciations-view__form-visibility-toggle--arrow'>
          <ArrowIcon />
        </span>
      </div>
      {AppreciationCategoryTabs}
    </div>
  )

  return (
    <BubbleBackground workspace>
      <Metadata meta={META.appreciations} />
      <MissingDataInfoMessage />
      <AuthPageTitle>
        <GreetingTitle />
        {isAdminUser && (
          <DeviceDetector>
            <div className='web-inline'>
              <PrimaryButton
                startIcon={<StyledAddUsersIcon />}
                onClick={navigateToManageUsers}
              >
                {t('manageUsersPage.invite.ctaButton')}
              </PrimaryButton>
            </div>
          </DeviceDetector>
        )}
      </AuthPageTitle>
      <AppreciationsViewContainer getFormHeight={getFormHeight} hasScrolled={hasScrolled} showMobileForm={createFormMobileVisible}>
        <div className='appreciations-view__create-and-tabs-container'>
          {AppreciationCreateForm('create-appreciation-form')}
          {AppreciationCategoryTabs}
        </div>
        {FixedAppreciationTabsAndFormMobile}
        <div ref={scrollContainerRef} className='appreciations-view__list'>
          <DeviceDetector>
            {selectedTab === 1 && (
              <>
                <div className='web'>
                  <AppreciationsList scrollContainerRef={scrollContainerRef} />
                </div>
                <div className='mobile'>
                  <AppreciationsList />
                </div>
              </>
            )}
            {selectedTab === 2 && (
              <>
                <div className='web'>
                  <ReceivedByAuthUserAppreciationsList
                    scrollContainerRef={scrollContainerRef}
                  />
                </div>
                <div className='mobile'>
                  <ReceivedByAuthUserAppreciationsList />
                </div>
              </>
            )}
            {selectedTab === 3 && (
              <>
                <div className='web'>
                  <GivenByAuthUserAppreciationsList
                    scrollContainerRef={scrollContainerRef}
                  />
                </div>
                <div className='mobile'>
                  <GivenByAuthUserAppreciationsList />
                </div>
              </>
            )}
          </DeviceDetector>
        </div>
      </AppreciationsViewContainer>
    </BubbleBackground>
  )
}

export default AppreciationsView

const AppreciationsViewContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 770px;
  padding: 20px;
  width: 100%;
  overflow-y: auto;
  height: 100%;

  ${props => props.theme.breakpointsMedia.mobile} {
    display: block;
    max-width: 100%;
    padding: 0;
    overflow-y: unset;
  }
  
  .appreciations-view__create-and-tabs-container {
    max-width: 730px;
    width: 100%;
    padding: 0 20px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      box-sizing: border-box;
      max-width: 100%;
      padding: 0;
    }
  }
  
  .appreciations-view__create-and-tabs {
    display: none;
    max-width: 730px;
    width: 100%;
    padding: 0 20px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      display: ${props => props.hasScrolled ? 'block' : 'none'};
      box-sizing: border-box;
      max-width: 100%;
      padding: 0;
      position: fixed;
      top: ${props => {
        switch (true) {
          case props.hasScrolled && props.showMobileForm:
            return `calc(${props.theme.dimensions.navHeight} - ${props.getFormHeight()}px)`
          case props.hasScrolled && !props.showMobileForm:
            return props.theme.dimensions.navHeight
          default:
            return 'auto'
        }
      }};
      left: ${props => props.hasScrolled ? '0' : 'auto'};
      z-index: ${props => props.hasScrolled ? props.theme.zIndex.appBar : '1'};
      background-color: ${props => props.hasScrolled ? props.theme.palette.common.catskillWhite : 'transparent'};
      transition: ${props =>
  `all ${props.theme.transitions.duration.complex}ms ${props.theme.transitions.easing.easeInOut}`};
    }
  }
  
  .appreciations-view__form-visibility-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;
    background-color: ${props => props.theme.palette.common.white};
    color: ${props => props.theme.palette.common.doveGray};
    
    .appreciations-view__form-visibility-toggle--arrow {
      color: ${props => props.theme.palette.primary.main};
      transform: ${props => props.showMobileForm ? 'none' : 'rotate(-180deg)'};
      transition: ${props =>
  `all ${props.theme.transitions.duration.complex}ms ${props.theme.transitions.easing.easeInOut}`};
    }
  }

  .appreciations-view__tabs {
    max-width: 670px;
    width: 100%;
    padding: 40px 20px 10px;

    ${props => props.theme.breakpointsMedia.mobile} {
      box-sizing: border-box;
      padding: 10px;
      max-width: 100%;
      overflow-x: auto;
    }
  }

  .appreciations-view__list-wrapper {
    padding: 0 15px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      padding: 0;
      display: flex;
      align-items: center;
    }
  }

  .appreciations-view__list {
    max-width: 670px;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    display: inline-block;
    flex: 2;
    overflow-y: auto;
    overflow-x: visible;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      max-width: 100%;
      padding: 0;
      overflow-y: unset;
    }
  }
`

const StyledAddUsersIcon = styled(AddUser)`
  font-size: 16px !important;
  color: ${props => props.theme.palette.common.white};
`
