import React from 'react'
import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'
import MuiModal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

import Paper from 'src/components/atoms/Paper'

export const Modal = (props) => {
  const { open, handleClose, children, title } = props
  const classes = useModalStyles()

  return (
    <MuiModal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: {
          opacity: '.6'
        },
        className: classes.backdrop
      }}
    >
      <Fade in={open}>
        <StyledPaper>
          {title && <div className='modal__title'>{title}</div>}
          {children}
        </StyledPaper>
      </Fade>
    </MuiModal>
  )
}

export default Modal

const useModalStyles = makeStyles((muiTheme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  backdrop: {
    backgroundColor: muiTheme.palette.common.alto
  }
}))

const StyledPaper = styled(Paper)`
  min-width: 500px;
  
  .modal__title {
    border-bottom: 1px solid ${props => props.theme.palette.common.gray400};
    padding-bottom: 15px;
    margin-bottom: 15px;
    font-size: 20px;
  }
  
  ${props => props.theme.breakpointsMedia.mobile} {
    min-width: 300px;
  }
`
