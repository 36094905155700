import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { getOrganisationAvailableReactions } from 'src/features/organisation/duck/selectors'

import Reaction from 'src/features/appreciations/components/Reaction'

export const ReactionsList = props => {
  const { appreciationId } = props

  // REDUX
  const availableReactions = useSelector(getOrganisationAvailableReactions)

  const renderReactions = availableReactions.map(reaction => (
    <Reaction
      key={reaction.content}
      reaction={reaction}
      appreciationId={appreciationId}
    />
  ))

  return <ReactionsListContainer>{renderReactions}</ReactionsListContainer>
}

export default ReactionsList

const ReactionsListContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
`
