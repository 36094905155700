import React from 'react'
import CreateCoreValueForm from 'src/features/coreValues/CreateCoreValueForm'
import CoreValuesList from 'src/features/coreValues/CoreValuesList'

export const CoreValues = (props) => {
  return (
    <div>
      <CoreValuesList />
      <CreateCoreValueForm />
    </div>
  )
}

export default CoreValues
