import styled from 'styled-components'

export default styled.h1`
  box-sizing: border-box;
  color: ${props => props.theme.palette.text.main};
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    margin: 0 0 10px 0;
  }
`
