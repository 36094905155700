import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { propOr } from 'ramda'
import {
  validateEditUserField,
  validateEditUserValues
} from 'src/features/users/duck/schema'
import { jobFunctionOptions } from 'src/utils/jobFunction'

import { updateUserRoutine } from 'src/features/users/duck/actions'

import Form from 'src/components/atoms/Form'
import Input from 'src/components/atoms/Input'
import Select from 'src/components/atoms/Select'
import Button from 'src/components/atoms/Button'

const emptyValues = {
  firstName: '',
  lastName: '',
  nickname: '',
  email: '',
  phoneNumber: '',
  jobFunction: '',
  id: ''
}

export const UpdateProfile = props => {
  const { user, onSubmit } = props

  //  REDUX
  const dispatch = useDispatch()

  const updateUser = useCallback(
    payload => dispatch(updateUserRoutine(payload)),
    [dispatch]
  )

  const [valid, _validateSchema] = useState(true)
  const [values, setValues] = useState(emptyValues)
  const [submitting, setIsSubmitting] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setInitialValues()
  }, [user])

  useEffect(() => {
    validateEditUserValues(values, _validateSchema)
  }, [values])

  const setInitialValues = () => {
    return setValues({
      firstName: propOr('', 'firstName')(user),
      lastName: propOr('', 'lastName')(user),
      email: propOr('', 'email')(user),
      id: propOr('', 'id')(user),
      phoneNumber: propOr('', 'phoneNumber')(user),
      jobFunction: propOr('', 'jobFunction')(user)
    })
  }

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSuccess = () => {
    onSubmit()
    setIsSubmitting(false)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setIsSubmitting(true)
    updateUser({ values, callback: handleSuccess })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        value={propOr('', 'firstName')(user)}
        required
        name='firstName'
        label={t('form.firstName.label')}
        onChange={handleOnChange}
        validate={validateEditUserField(values)}
      />
      <Input
        value={propOr('', 'lastName')(user)}
        required
        name='lastName'
        label={t('form.lastName.label')}
        onChange={handleOnChange}
        validate={validateEditUserField(values)}
      />
      <Input
        value={propOr('', 'nickname')(user)}
        name='nickname'
        label={t('form.nickname.label')}
        onChange={handleOnChange}
        validate={validateEditUserField(values)}
      />
      <Input
        value={propOr('', 'phoneNumber')(user)}
        name='phoneNumber'
        label={t('form.phoneNumber.label')}
        onChange={handleOnChange}
        validate={validateEditUserField(values)}
      />
      <Input
        value={propOr('', 'email')(user)}
        name='email'
        label={t('form.email.label')}
        onChange={handleOnChange}
        validate={validateEditUserField(values)}
      />
      <Select
        value={propOr('', 'jobFunction')(user)}
        required
        options={jobFunctionOptions()}
        name='jobFunction'
        label={t('form.jobFunction.label')}
        validate={validateEditUserField(values)}
        onChange={handleOnChange}
      />
      <ButtonContainer>
        <Button
          type='submit'
          disabled={submitting || !valid}
          onClick={handleSubmit}
        >
          {t('manageUsersPage.manageUsers.editUser.editCTA')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

export default UpdateProfile

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
