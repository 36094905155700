import styled from 'styled-components'

export const SectionContainer = styled.div`
  margin-bottom: 30px;
  display: inline-block;
  width: 100%;
`

export const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.palette.text.main};
`

export const SectionSubtitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${props => props.theme.palette.common.doveGray};
`

export const SectionTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SectionSubtitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
