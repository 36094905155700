import { API_STATES } from 'src/utils/api'
import { pathOr, propEq, pipe, sortBy, prop, reverse } from 'ramda'
import { createSelector } from 'reselect'
import { transformToArray } from 'src/utils/redux'

export const selectReports = state => state.reports

export const isReportsLoading = createSelector(
  selectReports,
  propEq('state', API_STATES.IN_PROGRESS)
)

export const getPointsReportUsersAsArray = createSelector(
  selectReports,
  pipe(
    pathOr([], ['pointsReport', 'users']),
    sortBy(prop('totalReceivedPoints')),
    reverse
  )
)

export const getPointsReportTotalPointsGiven = createSelector(
  selectReports,
  pipe(pathOr({}, ['pointsReport', 'totalPointsGiven']))
)

export const getAppreciationsReportTotalAppreciationCount = createSelector(
  selectReports,
  pipe(pathOr({}, ['appreciationsReport', 'totalAppreciationCount']))
)

export const getAppreciationsReportTotalReactionCount = createSelector(
  selectReports,
  pipe(pathOr({}, ['appreciationsReport', 'totalReactionCount']))
)

export const getAppreciationsReportTotalAppreciationAndReactionCount = createSelector(
  selectReports,
  pipe(pathOr({}, ['appreciationsReport', 'totalAppreciationAndReactionCount']))
)

export const getAppreciationsReportUsersAsArray = createSelector(
  selectReports,
  pipe(
    pathOr({}, ['appreciationsReport', 'users']),
    transformToArray,
    sortBy(prop('appreciationAndReactionCount')),
    reverse
  )
)
