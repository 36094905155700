import React, { useEffect, useState } from 'react'
import { take, pipe } from 'ramda'
import { mapIndexed } from 'src/utils/ramda'

export const AnimatedDots = props => {
  const [dotsNumber, setDotsNumber] = useState(1)
  const dots = ['.', '.', '.']

  useEffect(() => {
    setTimeout(changeDotsNumber, 700)
  }, [dotsNumber])

  const renderDots = () =>
    pipe(
      take(dotsNumber),
      mapIndexed((dot, index) => <span key={`dot-${index}`}>{dot}</span>)
    )(dots)

  const changeDotsNumber = () => {
    if (dotsNumber === 3) {
      setDotsNumber(1)
    } else {
      setDotsNumber(prevState => prevState + 1)
    }
  }

  return <span>{renderDots()}</span>
}

export default AnimatedDots
