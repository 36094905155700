import styled from 'styled-components'
import EmojiIcon from 'src/components/icons/Emoji'

export default styled(EmojiIcon)`
  font-size: 26px;
  color: ${props =>
    props.open
      ? props.theme.palette.secondary.main
      : props.theme.palette.common.alto};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.palette.common.gray};
  }
`
