import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { appreciationFormFields } from 'src/utils/appreciations'

import AppreciationIcon from 'src/assets/images/vectors/appreciation.svg'
import PointsIcon from 'src/assets/images/vectors/rating.svg'
import ReceiverMaleIcon from 'src/assets/images/vectors/userMale.svg'
import ReceiverFemaleIcon from 'src/assets/images/vectors/userFemale.svg'

export const CreateAppreciationIcon = props => {
  const { focusedFieldName } = props
  const [image, setImage] = useState(AppreciationIcon)

  useEffect(() => {
    const isReceiversSection = focusedFieldName === 'receivers'
    const hasAlreadyReceiversSectionImage =
      image === ReceiverFemaleIcon || image === ReceiverMaleIcon
    if (isReceiversSection && hasAlreadyReceiversSectionImage) {
      const nextImage =
        image === ReceiverMaleIcon ? ReceiverFemaleIcon : ReceiverMaleIcon
      setTimeout(() => setImage(nextImage), 1500)
    } else {
      setIcon()
    }
  }, [image, focusedFieldName])

  const setIcon = () => {
    switch (true) {
      case focusedFieldName === appreciationFormFields.content:
        return setImage(AppreciationIcon)
      case focusedFieldName === appreciationFormFields.points:
        return setImage(PointsIcon)
      case focusedFieldName === appreciationFormFields.receivers:
        return setImage(ReceiverMaleIcon)
      default:
        return setImage(AppreciationIcon)
    }
  }

  return <StyledImage src={image} />
}

CreateAppreciationIcon.defaultProps = {
  focusedFieldName: appreciationFormFields.content,
  receivers: []
}

export default CreateAppreciationIcon

const StyledImage = styled.div`
  width: 35px;
  height: 35px;
  margin-right: 18px;
  background-image: url(${props => props.src});
  background-size: contain;
  background-repeat: no-repeat;
  transition: ${props =>
    `background ${props.theme.transitions.duration.complex}ms ${props.theme.transitions.easing.easeInOut}`};

  ${props => props.theme.breakpointsMedia.mobile} {
    margin-right: 3px;
    width: 25px;
    height: 25px;
  }
`
