import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  validateCoreValueField,
  validateCoreValueValues
} from 'src/features/coreValues/duck/schema'

import { createCoreValueRoutine } from 'src/features/coreValues/duck/actions'
import { isCoreValuesLoading } from 'src/features/coreValues/duck/selectors'

import Form from 'src/components/atoms/Form'
import Button from 'src/components/atoms/Button'
import Input from 'src/components/atoms/Input'

const emptyValues = {
  coreValueName: ''
}

export const CreateCoreValueForm = props => {
  //  REDUX
  const dispatch = useDispatch()
  const isLoading = useSelector(isCoreValuesLoading)
  const createCoreValue = useCallback(
    payload => dispatch(createCoreValueRoutine(payload)),
    [dispatch]
  )

  const { t } = useTranslation()
  const [values, setValues] = useState(emptyValues)
  const [valid, _validateSchema] = useState(true)
  const [submitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    isLoading === false && setIsSubmitting(false)
  }, [isLoading])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  useEffect(() => {
    validateCoreValueValues(values, _validateSchema)
  }, [values])

  const handleSubmit = async e => {
    e.preventDefault()
    setIsSubmitting(true)
    createCoreValue({ name: values.coreValueName })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormContainer>
        <Input
          required
          name='coreValueName'
          type='text'
          label={t('form.coreValue.label')}
          onChange={handleOnChange}
          validate={validateCoreValueField(values)}
          reset={submitting}
        />
        <ButtonContainer>
          <Button
            type='submit'
            disabled={(isLoading && submitting) || !valid}
            onClick={handleSubmit}
            variant='contained'
            color='primary'
            id='submit-core-value-name'
          >
            {t('myAccountPage.updateCTA')}
          </Button>
        </ButtonContainer>
      </FormContainer>
    </Form>
  )
}

export default CreateCoreValueForm

const FormContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
`

const ButtonContainer = styled.div`
  margin-left: 10px;
  margin-top: 10px;
`
