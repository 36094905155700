import React from 'react'
import { useTranslation } from 'react-i18next'

import AuthPageTitle from 'src/components/atoms/AuthPageTitle'
import Grid from 'src/components/atoms/Grid'
import Paper from 'src/components/atoms/Paper'
import OrganisationDetails from 'src/features/organisation/OrganisationDetails'
import OrganisationSettings from 'src/features/organisation/OrganisationSettings'
import PaddingContainer from 'src/components/PaddingContainer'
import CoreValues from 'src/features/coreValues/CoreValues'
import AddToSlack from 'src/features/integrations/components/AddToSlack'
import PaperTitle from 'src/components/atoms/PaperTitle'

export const OrganisationSettingsView = props => {
  const { t } = useTranslation()

  return (
    <PaddingContainer>
      <AuthPageTitle>{t('organisationSettingsPage.pageTitle')}</AuthPageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2} />
        <Grid item xs={12} sm={8}>
          <Paper variant='outlined'>
            <OrganisationSettings />
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2} />
        <Grid item xs={12} sm={8}>
          <Paper variant='outlined'>
            <CoreValues />
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2} />
        <Grid item xs={12} sm={8}>
          <Paper variant='outlined'>
            <OrganisationDetails />
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2} />
        <Grid item xs={12} sm={8}>
          <Paper variant='outlined'>
            <PaperTitle>{t('integrationsPage.pageTitle')}</PaperTitle>
            <AddToSlack />
          </Paper>
        </Grid>
      </Grid>
    </PaddingContainer>
  )
}

export default OrganisationSettingsView
