import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { take, pipe, isEmpty } from 'ramda'
import { isNotNilOrEmpty, mapIndexed } from 'src/utils/ramda'
import { currentYearMonth } from 'src/utils/date'

import {
  getAppreciationsReportRoutine,
  getPointsReportRoutine
} from 'src/features/reports/duck/actions'
import { getHasPointSystem } from 'src/features/organisation/duck/selectors'
import {
  getAppreciationsReportUsersAsArray,
  getPointsReportUsersAsArray
} from 'src/features/reports/duck/selectors'

import UserAvatar from 'src/components/UserAvatar'
import Star from 'src/components/icons/Star'
import Performance from 'src/components/icons/Performance'
import CountBubble from 'src/assets/images/vectors/countBubble.svg'
import EmptyState from 'src/components/EmptyState'

export const AuthSideBarLeadershipList = props => {
  const [pointsSystemData, setPointsSystemData] = useState([])
  const [nonPointsSystemData, setNonPointsSystemData] = useState([])

  // REDUX
  const dispatch = useDispatch()
  const hasPointSystem = useSelector(getHasPointSystem)
  const pointsReportData = useSelector(getPointsReportUsersAsArray)
  const appreciationsReportData = useSelector(
    getAppreciationsReportUsersAsArray
  )
  const getAppreciationsReport = useCallback(
    payload => dispatch(getAppreciationsReportRoutine(payload)),
    [dispatch]
  )
  const getPointsReport = useCallback(
    payload => dispatch(getPointsReportRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    if (hasPointSystem) {
      getPointsReport(currentYearMonth)
    } else {
      getAppreciationsReport(currentYearMonth)
    }
  }, [hasPointSystem])

  useEffect(() => {
    if (hasPointSystem && isEmpty(pointsSystemData)) {
      setPointsSystemData(pointsReportData)
    } else if (hasPointSystem === false && isEmpty(nonPointsSystemData)) {
      setNonPointsSystemData(appreciationsReportData)
    }
  }, [hasPointSystem, pointsReportData, appreciationsReportData])

  const fixIfNumber = (e) => typeof e === 'number' ? e.toFixed(2) : e

  const dataRow = (entity, index) => (
    <LeadershipListElement
      key={`leadership-list-element-${entity.firstName}-${entity.lastName}`}
    >
      <div className='leadership-list-element__avatar-number'>{index + 1}.</div>
      <div className='leadership-list-element__avatar'>
        <UserAvatar user={entity} />
      </div>
      <div className='leadership-list-element__aside'>
        <div className='leadership-list-element__aside-name'>
          {entity.firstName}&nbsp;{entity.lastName}
        </div>
        <div className='leadership-list-element__aside-details'>
          <div className='leadership-list-element__aside-icon'>
            <Star />
            <span>
              &nbsp;
              {hasPointSystem
                ? entity.totalReceivedPoints
                : entity.appreciationAndReactionCount}
            </span>
          </div>
          <div className='leadership-list-element__aside-dot'>
            &nbsp;•&nbsp;
          </div>
          <div className='leadership-list-element__aside-icon'>
            <Performance />
            <span>
              &nbsp;
              {hasPointSystem
                ? fixIfNumber(entity.pointRatio)
                : fixIfNumber(entity.appreciationAndReactionRatio)}
            </span>
          </div>
        </div>
      </div>
    </LeadershipListElement>
  )

  const data = hasPointSystem ? pointsSystemData : nonPointsSystemData
  const renderRows = pipe(take(5), mapIndexed(dataRow))(data)

  return <>{isNotNilOrEmpty(data) ? renderRows : <EmptyState />}</>
}

export default AuthSideBarLeadershipList

const LeadershipListElement = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
  
  .leadership-list-element__aside-name {
    font-size: 17px;
  }

  .leadership-list-element__avatar-number {
    background-image: url('${CountBubble}');
    height: 19px;
    width: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    color: ${props => props.theme.palette.common.white};
    text-align: center;
    font-size: 13px;
    font-weight: 700;
    margin-right: 3px;
    transform: translateY(-8px)
  }

  .leadership-list-element__aside {
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 14px;
    color: ${props => props.theme.palette.common.osloGray};
  }

  .leadership-list-element__aside-icon {
    font-size: 13px;
    display: flex;
    align-items: center;
  }

  .leadership-list-element__aside-icon svg {
    margin-right: 5px;
  }

  .leadership-list-element__aside-icon {
    font-size: 14px;
    color: ${props => props.theme.palette.common.osloGray};
  }

  .leadership-list-element__aside-dot {
    font-size: 18px;
    color: ${props => props.theme.palette.secondary.main};
  }

  .leadership-list-element__aside-details {
    display: flex;
    align-items: center;
  }
`
