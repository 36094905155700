import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { currentYearMonth } from 'src/utils/date'
import { map } from 'ramda'
import { copyElementToClipboardById } from 'src/utils/clipboard'
import { SEVERITY } from 'src/utils/toast'
import { isNotNilOrEmpty } from 'src/utils/ramda'
import { toFixedOrDefault } from 'src/utils/math'

import { getAppreciationsReportRoutine } from 'src/features/reports/duck/actions'
import { showToastRoutine } from 'src/features/toast/duck/actions'
import {
  getAppreciationsReportTotalAppreciationAndReactionCount,
  getAppreciationsReportTotalAppreciationCount,
  getAppreciationsReportTotalReactionCount,
  getAppreciationsReportUsersAsArray
} from 'src/features/reports/duck/selectors'

import {
  Table,
  TableBody,
  TableCell,
  TableHeaderCell,
  TableHead,
  TableRow,
  TableFooter
} from 'src/components/atoms/Table/Table'

import SelectReportMonth from 'src/features/reports/components/SelectReportMonth'
import Button from 'src/components/atoms/Button'
import FileCopyIcon from 'src/components/icons/FileCopy'
import Tooltip from 'src/components/atoms/Tooltip'
import EmptyState from 'src/components/EmptyState'

export const UserAppreciationsAmountReport = props => {
  //  REDUX
  const dispatch = useDispatch()
  const appreciationsReportTotalAppreciationCount = useSelector(
    getAppreciationsReportTotalAppreciationCount
  )
  const appreciationsReportTotalReactionCount = useSelector(
    getAppreciationsReportTotalReactionCount
  )
  const appreciationsReportTotalAppreciationAndReactionCount = useSelector(
    getAppreciationsReportTotalAppreciationAndReactionCount
  )
  const appreciationsReportData = useSelector(
    getAppreciationsReportUsersAsArray
  )
  const getAppreciationsReport = useCallback(
    payload => dispatch(getAppreciationsReportRoutine(payload)),
    [dispatch]
  )
  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const { t } = useTranslation()

  useEffect(() => {
    getAppreciationsReport(currentYearMonth)
  }, [])

  const handleChangeDate = values => getAppreciationsReport(values)

  const dataRow = entity => (
    <TableRow
      key={`appreciations-report-${entity.firstName}-${entity.lastName}`}
    >
      <TableCell>{entity.firstName}</TableCell>
      <TableCell>{entity.lastName}</TableCell>
      <TableCell>{entity.appreciationCount}</TableCell>
      <TableCell>{toFixedOrDefault(entity.appreciationRatio)}</TableCell>
      <TableCell>{entity.reactionCount}</TableCell>
      <TableCell>{toFixedOrDefault(entity.reactionRatio)}</TableCell>
      <TableCell>{entity.appreciationAndReactionCount}</TableCell>
      <TableCell>
        {toFixedOrDefault(entity.appreciationAndReactionRatio)}
      </TableCell>
    </TableRow>
  )

  const TableEmptyState = (
    <TableRow>
      <TableCell colSpan={8}>
        <EmptyStateContainer>
          <EmptyState text={t('manageUsersPage.manageUsers.empty')} />
        </EmptyStateContainer>
      </TableCell>
    </TableRow>
  )

  const renderRows = map(dataRow)(appreciationsReportData)
  const tableId = 'UserAppreciationsAmountReport'

  const handleCopyTable = e => {
    e.preventDefault()
    copyElementToClipboardById(tableId)
    showToast({
      key: 'toast.copied',
      severity: SEVERITY.success
    })
  }

  return (
    <>
      <ManageTableHeader>
        <Tooltip title={t('reportsPage.copy')}>
          <ButtonContainer>
            <Button onClick={handleCopyTable} variant='outlined'>
              <FileCopyIcon />
            </Button>
          </ButtonContainer>
        </Tooltip>
        <SelectReportMonth
          value={currentYearMonth}
          onChange={handleChangeDate}
        />
      </ManageTableHeader>
      <TableContainer>
        <Table id={tableId} aria-label='points table'>
          <TableHead>
            <TableRow>
              <TableHeaderCell>
                {t('reportsPage.appreciationsReportTableHeaders.firstName')}
              </TableHeaderCell>
              <TableHeaderCell>
                {t('reportsPage.appreciationsReportTableHeaders.lastName')}
              </TableHeaderCell>
              <TableHeaderCell>
                {t(
                  'reportsPage.appreciationsReportTableHeaders.appreciationCount'
                )}
              </TableHeaderCell>
              <TableHeaderCell>
                {t(
                  'reportsPage.appreciationsReportTableHeaders.appreciationRatio'
                )}
              </TableHeaderCell>
              <TableHeaderCell>
                {t('reportsPage.appreciationsReportTableHeaders.reactionCount')}
              </TableHeaderCell>
              <TableHeaderCell>
                {t('reportsPage.appreciationsReportTableHeaders.reactionRatio')}
              </TableHeaderCell>
              <TableHeaderCell>
                {t(
                  'reportsPage.appreciationsReportTableHeaders.appreciationAndReactionCount'
                )}
              </TableHeaderCell>
              <TableHeaderCell>
                {t(
                  'reportsPage.appreciationsReportTableHeaders.appreciationAndReactionRatio'
                )}
              </TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isNotNilOrEmpty(appreciationsReportData)
              ? renderRows
              : TableEmptyState}
          </TableBody>
          {isNotNilOrEmpty(appreciationsReportData) && (
            <TableFooter>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell>{appreciationsReportTotalAppreciationCount}</TableCell>
                <TableCell>1</TableCell>
                <TableCell>{appreciationsReportTotalReactionCount}</TableCell>
                <TableCell>1</TableCell>
                <TableCell>
                  {appreciationsReportTotalAppreciationAndReactionCount}
                </TableCell>
                <TableCell>1</TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </>
  )
}

export default UserAppreciationsAmountReport

const ManageTableHeader = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ButtonContainer = styled.div`
  margin-right: 20px;
`

const EmptyStateContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TableContainer = styled.div`
  display: inline-block;
  width: 100%;
  overflow-x: auto;
`
