import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import qs from 'qs'
import { pipe, take } from 'ramda'
import { mapIndexed, isNotNilOrEmpty } from 'src/utils/ramda'
import { daysFromNowOrDate } from 'src/utils/date'

import { fetchReceivedByAuthUserAppreciationsRoutine } from 'src/features/appreciations/duck/actions'
import { getAuthUserReceivedAppreciations } from 'src/features/appreciations/duck/selectors'
import { getHasPointSystem } from 'src/features/organisation/duck/selectors'

import UserAvatar from 'src/components/UserAvatar'
import Heart from 'src/components/icons/Heart'
import Calendar from 'src/components/icons/Calendar'
import EmptyState from 'src/components/EmptyState'

export const AuthSideBarRecentlyThankedList = props => {
  // REDUX
  const dispatch = useDispatch()
  const hasPointSystem = useSelector(getHasPointSystem)
  const appreciations = useSelector(getAuthUserReceivedAppreciations)
  const fetchAppreciations = useCallback(
    query => dispatch(fetchReceivedByAuthUserAppreciationsRoutine(query)),
    [dispatch]
  )

  useEffect(() => {
    fetchAppreciations(
      qs.stringify({
        limit: {
          page: 1,
          take: 5
        },
        order: {
          by: 'created_at',
          dir: 'DESC'
        }
      })
    )
  }, [])

  const dataRow = (entity, index) => {
    const { giver, points, createdAt } = entity

    return (
      <RecentlyThankedElement
        key={`recently-thanked-element-${giver.firstName}-${giver.lastName}-${index}`}
      >
        <div>
          <UserAvatar user={giver} />
        </div>
        <div className='recently-thanked-element__aside'>
          <div className='recently-thanked-element__aside-name'>
            {giver.firstName}&nbsp;{giver.lastName}
          </div>
          <div className='recently-thanked-element__aside-details'>
            {hasPointSystem && (
              <>
                <div className='recently-thanked-element__aside-icon recently-thanked-element__aside-icon-heart'>
                  <Heart />
                  <span>&nbsp;{points}</span>
                </div>
                <div className='recently-thanked-element__aside-dot'>
                  &nbsp;•&nbsp;
                </div>
              </>
            )}
            <div className='recently-thanked-element__aside-icon'>
              <Calendar />
              <span>&nbsp;{daysFromNowOrDate(createdAt)}</span>
            </div>
          </div>
        </div>
      </RecentlyThankedElement>
    )
  }

  const renderRows = pipe(take(5), mapIndexed(dataRow))(appreciations)

  return <>{isNotNilOrEmpty(appreciations) ? renderRows : <EmptyState />}</>
}

export default AuthSideBarRecentlyThankedList

const RecentlyThankedElement = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }

  .recently-thanked-element__aside-name {
    font-size: 17px;
  }

  .recently-thanked-element__aside {
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 14px;
    color: ${props => props.theme.palette.common.osloGray};
  }

  .recently-thanked-element__aside-icon {
    font-size: 13px;
    display: flex;
    align-items: center;
  }

  .recently-thanked-element__aside-icon svg {
    margin-right: 5px;
  }

  .recently-thanked-element__aside-icon {
    font-size: 13px;
    color: ${props => props.theme.palette.common.osloGray};
  }

  .recently-thanked-element__aside-icon-heart svg {
    font-size: 13px;
    color: ${props => props.theme.palette.primary.main};
  }

  .recently-thanked-element__aside-dot {
    font-size: 18px;
    color: ${props => props.theme.palette.secondary.main};
  }

  .recently-thanked-element__aside-details {
    display: flex;
    align-items: center;
  }
`
