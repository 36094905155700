import React, { useCallback } from 'react'
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux'
import { getOrganisationDetails } from 'src/features/organisation/duck/selectors'
import { hasOrganisationIntegration, getIntegrationChannelName } from 'src/utils/integrations'
import Slack from 'src/assets/images/vectors/slack.svg'
import { useTranslation } from 'react-i18next'
import DeleteButtonWithConfirm from 'src/components/DeleteButtonWithConfirm'
import { deleteIntegration } from 'src/services/OrganisationService'
import { showToastRoutine } from 'src/features/toast/duck/actions'
import { SEVERITY } from 'src/utils/toast'
import { getOrganisationDetailsRoutine } from 'src/features/organisation/duck/actions'

export const AddToSlack = props => {
  const dispatch = useDispatch()
  const organisation = useSelector(getOrganisationDetails)
  const hasSlackIntegration = hasOrganisationIntegration('slack')(organisation)
  const slackChannelName = getIntegrationChannelName('slack')(organisation)
  const { t } = useTranslation()

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const fetchOrganisationDetails = useCallback(
    () => dispatch(getOrganisationDetailsRoutine()),
    [dispatch]
  )

  const handleRemoveIntegration = type => {
    const handleSuccess = () => {
      fetchOrganisationDetails()
      showToast({
        key: 'toast.integrationDeleteSuccess',
        severity: SEVERITY.success
      })
    }
    const handleError = e => {
      console.error(e)
      showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    }

    deleteIntegration({ type })
      .then(handleSuccess)
      .catch(handleError)
  }

  const AddButton = (
    <AddToSlackButtonContainer hasSlackIntegration={hasSlackIntegration}>
      <img className='add-to-slack-button__logo' src={Slack} alt='Add to Slack'/>
      <div className='add-to-slack-button__text'>{t(`integrationsPage.slack.${hasSlackIntegration ? 'added' : 'add'}`)}</div>
      {
        hasSlackIntegration && <IntegrationChannelName>{slackChannelName}</IntegrationChannelName>
      }
      {
        hasSlackIntegration && (
          <DeleteButtonWithConfirm
            text={t('integrationsPage.slack.remove.confirmation')}
            submitText={t('integrationsPage.slack.remove.submit')}
            cancelText={t('integrationsPage.slack.remove.cancel')}
            id='slack'
            onSubmit={handleRemoveIntegration}
          />
        )
      }
    </AddToSlackButtonContainer>
  )

  return hasSlackIntegration ? AddButton : (
    <a
      href={`https://slack.com/oauth/v2/authorize?client_id=${process.env.GATSBY_SLACK_CLIENT_ID}&scope=app_mentions:read,channels:join,channels:read,chat:write,chat:write.public,commands,groups:read,im:history,im:read,im:write,incoming-webhook,mpim:history,reactions:read,reactions:write,team:read,users:read,users:read.email&user_scope=reactions:read,reactions:write,users:read,channels:write`}
    >
      {AddButton}
    </a>
  )
}

export default AddToSlack

const AddToSlackButtonContainer = styled.div`
  cursor: pointer;
  padding: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: ${props => props.theme.shadows.main};
  color: ${props => props.hasSlackIntegration ? props.theme.palette.success.main : props.theme.palette.text.main};
  transition: ${props =>
  `all ${props.theme.transitions.duration.standard}ms ${props.theme.transitions.easing.easeInOut}`};

  .add-to-slack-button__logo {
    max-width: 20px;
  }
  
  .add-to-slack-button__text {
    margin-left: 10px;
  }
  
  .add-to-slack-button__checkmark {
    margin-left: 10px;
  }
  
  button {
    margin-left: 10px;
  }
  
  &:hover {
   background-color: ${props => props.hasSlackIntegration ? 'none' : props.theme.palette.common.catskillWhite};
  }
  
  &:focus,
  &:active {
   background-color: ${props => props.hasSlackIntegration ? 'none' : props.theme.palette.common.titanWhite};
  }
`

const IntegrationChannelName = styled.div`
  display: inline-block;
  margin-left: 6px;
`
