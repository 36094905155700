import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  wordOfPraiseTranslationKeys,
  getRandomElementFromArray,
  getPointsName
} from 'src/utils/appreciations'
import { formatDate, FORMATS, takeLastMinuteDigit } from 'src/utils/date';

import {
  getHasPointSystem, getOrganisationPointsPluralName
} from 'src/features/organisation/duck/selectors'

import Paper from 'src/components/atoms/Paper'
import UserMention from 'src/features/appreciations/components/UserMention'
import ReactionsList from 'src/features/appreciations/components/ReactionsList'
import SelectedCoreValues
  from 'src/features/appreciations/components/CreateAppreciationInput/components/SelectedCoreValues';

const shouldAvoidUpdate = (prevProps, nextProps) =>
  prevProps.data.id === nextProps.data.id

const renderReceiversMentions = (receivers = []) =>
  receivers.map(receiver => <UserMention user={receiver} key={receiver.id} />)

const getWordOfPraiseTranslationKey = createdAtDate =>
  getRandomElementFromArray(
    wordOfPraiseTranslationKeys,
    takeLastMinuteDigit(createdAtDate)
  )

const getPointsSummary = (points, pointsName) =>
  `${points.toString()} ${pointsName}`

export const Appreciation = memo(props => {
  const {
    data: { id, points, content, giver, receivers, createdAt }
  } = props

  // REDUX
  const hasPointSystem = useSelector(getHasPointSystem)
  const pointsPluralName = useSelector(getOrganisationPointsPluralName)

  const { t } = useTranslation()

  const hasNonPointSystemOrGave0Points = !hasPointSystem || points === 0

  const appreciationContent = () =>
    hasNonPointSystemOrGave0Points ? (
      <>
        <UserMention user={giver} />
        {t('appreciations.justAppreciated')}&nbsp;
        {renderReceiversMentions(receivers)}
        {t('appreciations.for')}&nbsp;{content}
      </>
    ) : (
      <>
        <UserMention user={giver} />
        {t('appreciations.justGave')}&nbsp;
        <AppreciationPoints>
          {getPointsSummary(points, getPointsName(t, points, pointsPluralName))}
        </AppreciationPoints>
        &nbsp;
        {renderReceiversMentions(receivers)}
        {t('appreciations.for')}&nbsp;{content}
      </>
    )

  return (
    <AppreciationContainer>
      <Paper variant='outlined'>
        <AppreciationHeading>
          <Heading>
            {t(getWordOfPraiseTranslationKey(createdAt))}
          </Heading>
          <Timestamp>{formatDate(createdAt, FORMATS.dashedTime)}</Timestamp>
        </AppreciationHeading>
        {appreciationContent()}
        <SelectedCoreValues values={{ appreciation: props.data }} />
        <ReactionsList appreciationId={id} />
      </Paper>
    </AppreciationContainer>
  )
}, shouldAvoidUpdate)

export default Appreciation

const AppreciationContainer = styled.div`
  margin-bottom: 20px;
  min-height: 130px;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    min-height: auto;
  }
`

const AppreciationPoints = styled.span`
  font-weight: 700;
`

const Timestamp = styled.div`
  margin-bottom: 16px;
  color: ${props => props.theme.palette.common.alto};
`

const Heading = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 600;
`

const AppreciationHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
