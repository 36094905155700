import { string, object } from 'yup'
import {
  validateValues,
  validateField
} from 'src/utils/form'

// Create core value schema

const coreValueSchema = object().shape({
  coreValueName: string().required(() => ({ key: 'form.coreValue.errors.required' }))
})

export const validateCoreValueField = values =>
  validateField(coreValueSchema, values)
export const validateCoreValueValues = validateValues(coreValueSchema)
