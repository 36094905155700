import { string, object, array } from 'yup'
import {
  validateValues,
  validateField,
  validateEmailByError
} from 'src/utils/form'
import { EMAIL_VALIDATION_ERRORS } from 'src/utils/errors'

// Sign in form validator

const emailSchema = object().shape({
  label: string(),
  value: string()
    .email(() => ({ key: 'form.emails.errors.invalid' }))
    .test(
      'is-organisation-member',
      () => ({ key: 'form.emails.errors.exists' }),
      validateEmailByError(EMAIL_VALIDATION_ERRORS.emailAlreadyExists)
    )
    .test(
      'has-pending-invitation',
      () => ({ key: 'form.emails.errors.pendingInvitation' }),
      validateEmailByError(EMAIL_VALIDATION_ERRORS.pendingInvitation)
    )
})

export const inviteUsersSchema = object().shape({
  emails: array()
    .required(() => ({ key: 'form.emails.errors.required' }))
    .of(emailSchema),
  role: string().required(() => ({ key: 'form.role.errors.required' }))
})

export const validateInviteUsersField = values =>
  validateField(inviteUsersSchema, values)
export const validateInviteUsersValues = validateValues(inviteUsersSchema)

// Edit User Schema

export const editUserSchema = object().shape({
  firstName: string().required(() => ({
    key: 'form.firstName.errors.required'
  })),
  lastName: string().required(() => ({ key: 'form.lastName.errors.required' })),
  nickname: string(),
  id: string().required(),
  email: string()
    .required(() => ({ key: 'form.email.errors.required' }))
    .email(() => ({ key: 'form.email.errors.invalid' })),
  phoneNumber: string()
    .nullable()
    .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$|^$|^\s$/g, () => ({
      key: 'form.phoneNumber.errors.invalid'
    })),
  jobFunction: string().required(() => ({
    key: 'form.jobFunction.errors.required'
  }))
})

export const validateEditUserField = values =>
  validateField(editUserSchema, values)
export const validateEditUserValues = validateValues(editUserSchema)
