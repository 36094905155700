import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { always, ifElse, length, pathOr, pipe, isEmpty } from 'ramda'
import { isNotNilOrEmpty } from 'src/utils/ramda'
import { getPointsName } from 'src/utils/appreciations'

import {
  getHasPointSystem
} from 'src/features/organisation/duck/selectors'

import { getOrganisationPointsPluralName } from 'src/features/organisation/duck/selectors'

import Button from 'src/components/atoms/Button'

export const CreateAppreciationButton = props => {
  const { disabled, onClick, values } = props

  // REDUX
  const hasPointSystem = useSelector(getHasPointSystem)
  const pointsPluralName = useSelector(getOrganisationPointsPluralName)

  const { t } = useTranslation()
  const pointsSummary = () => {
    // This is to generate default points amount shown on button "Give 100 points"
    // If points are found, but they equals 0 I want to get 100 by default (I don't want to multiply by 0)
    const points = pathOr('', ['appreciation', 'points'])(values)
    // If array is found but it is empty I want to get 1 by default (I don't want to multiply by 0)
    const receiversLength = pipe(
      pathOr([1], ['appreciation', 'receivers']),
      ifElse(isNotNilOrEmpty, length, always(1))
    )(values)

    return points > 0 ? points * receiversLength : ''
  }

  const getButtonName = () => {
    // If are not found I wan't to get the plural version for all languages
    const points = isEmpty(pointsSummary()) ? 2 : pointsSummary()
    const pointButtonName = isEmpty(pointsSummary()) ? t('dashboardPage.appreciationCTAEmpty') : t('dashboardPage.appreciationCTA') +
      ' ' +
      pointsSummary() +
      ' ' +
      getPointsName(t, points, pointsPluralName)

    return hasPointSystem
      ? pointButtonName
      : t('dashboardPage.appreciationCTA')
  }

  return (
    <Button
      type='submit'
      color='secondary'
      disabled={disabled}
      onClick={onClick}
      id='submit-create-appreciation'
    >
      {getButtonName()}
    </Button>
  )
}

export default CreateAppreciationButton
