import styled from 'styled-components'

export default styled.div`
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  cursor: pointer;
  color: ${props =>
    props.selected
      ? props.theme.palette.primary.main
      : props.theme.palette.text.main};

  &:not(:last-of-type) {
    margin-right: 20px;
  }

  &:hover {
    color: ${props => props.theme.palette.primary.dark};
  }
`
