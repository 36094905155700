import React, { useRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import AsyncSelect from 'react-select/async'
import {
  REACT_SELECT_STYLES,
  customComponents
} from 'src/features/appreciations/components/CreateAppreciationInput/components/styles'
import { searchOrganisationUsers } from 'src/services/UsersService'
import {
  applySpec,
  join,
  juxt,
  map,
  pipe,
  prop,
  propEq,
  not,
  filter
} from 'ramda'
import { isNotNilOrEmpty } from 'src/utils/ramda'

import { getHasPointSystem } from 'src/features/organisation/duck/selectors'
import CreatableSelect from 'react-select/creatable/dist/react-select.esm';

export const CreateAppreciationReceivers = props => {
  const { t } = useTranslation()
  const { onChange, onBlur, onFocus, name, pointsValue, reset } = props

  // REDUX
  const hasPointSystem = useSelector(getHasPointSystem)

  const [value, setValue] = useState(null)
  const SelectRef = useRef(null)

  useEffect(() => {
    if (isNotNilOrEmpty(pointsValue) && pointsValue !== 0) {
      SelectRef.current.focus()
    }
  }, [pointsValue])

  useEffect(() => {
    if (reset) {
      setValue(null)
    }
  }, [reset])

  const handleSearchUsers = query => {
    return searchOrganisationUsers(query)
      .then(response => response.data)
      .then(
        map(
          applySpec({
            value: prop('id'),
            label: pipe(juxt([prop('firstName'), prop('lastName')]), join(' '))
          })
        )
      )
  }

  const handleReceiversChange = selectedValues => {
    const receiversIds = isNotNilOrEmpty(selectedValues)
      ? map(prop('value'))(selectedValues)
      : []
    setValue(selectedValues)
    onChange(name, receiversIds)
  }

  const handleOnBlur = () => onBlur(name)
  const handleOnFocus = () => onFocus(name)
  const handleRemove = receiver => () => {
    const filteredValues = filter(pipe(propEq('value', receiver.value), not))(
      value
    )
    handleReceiversChange(filteredValues)
  }

  const renderReceivers = () => {
    const receivers = value || []

    return receivers.map(receiver => (
      <ReceiverContainer key={`receiver-${receiver.value}`}>
        <span>{receiver.label}</span>
        <span
          onClick={handleRemove(receiver)}
          className='receiver-container__remove'
        >
          ×
        </span>
      </ReceiverContainer>
    ))
  }

  const getPlaceholder = e =>
    isNotNilOrEmpty(e.inputValue)
      ? t('appreciations.noResults')
      : t('appreciations.searchUser')

  return (
    <>
      <span className='appreciation-input__separator'>
        {hasPointSystem && ' / '}
        <span className='appreciation-input__separator-at'>@</span>
      </span>
      {renderReceivers()}
      <AsyncSelect
        components={customComponents}
        value={value}
        ref={SelectRef}
        onChange={handleReceiversChange}
        styles={REACT_SELECT_STYLES}
        isSearchable
        isMulti
        placeholder={t('appreciations.someone')}
        noOptionsMessage={getPlaceholder}
        loadOptions={handleSearchUsers}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        instanceId='appreciation-receivers'
        id='appreciation-receivers'
      />
    </>
  )
}

CreateAppreciationReceivers.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  isFocused: false
}

export default CreateAppreciationReceivers

const ReceiverContainer = styled.div`
  display: flex;
  color: ${props => props.theme.palette.text.main};
  background-color: ${props => props.theme.palette.common.gallery};
  padding: 2px 5px;
  border-radius: 2px;
  margin-right: 3px;
  margin-bottom: 6px;
  margin-top: 6px;
  white-space: nowrap;

  .receiver-container__remove {
    padding: 0 0 0 2px;
    cursor: pointer;
  }
`
