import React from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { isNotNilOrEmpty } from 'src/utils/ramda'
import { getFirstName } from 'src/features/auth/duck/selectors'

import DeviceDetector from 'src/components/DeviceDetector'

export const GreetingPageTitle = props => {
  const { t } = useTranslation()
  const firstName = useSelector(getFirstName)

  return (
    <GreetingPageTittle>
      <DeviceDetector>
        <span className='greeting-title__text-big web-inline'>
          {t('dashboardPage.welcome')},&nbsp;
        </span>
        <span className='greeting-title__text-big mobile-inline'>
          {isNotNilOrEmpty(firstName) ? firstName : t('dashboardPage.welcome')},&nbsp;
        </span>
        <span>{t('dashboardPage.greetings')}</span>
      </DeviceDetector>
    </GreetingPageTittle>
  )
}

export default GreetingPageTitle

export const GreetingPageTittle = styled.div`
  box-sizing: border-box;
  color: ${props => props.theme.palette.text.main};
  font-size: 12px;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  text-transform: lowercase;

  .greeting-title__text-big {
    font-size: 22px;
    font-weight: 500;
    text-transform: capitalize;
  }
`
