import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { META } from 'src/utils/seo'

import { getHasPointSystem } from 'src/features/organisation/duck/selectors'

import Tabs from 'src/components/atoms/Tabs'
import Tab from 'src/components/atoms/Tab'
import Paper from 'src/components/atoms/Paper'
import AuthPageTitle from 'src/components/atoms/AuthPageTitle'
import UserAppreciationsPointsReport from 'src/features/reports/components/UserAppreciationsPointsReport'
import UserAppreciationsAmountReport from 'src/features/reports/components/UserAppreciationsAmountReport'
import Grid from 'src/components/atoms/Grid'
import Metadata from 'src/components/Metadata'
import PaddingContainer from 'src/components/PaddingContainer'

export const UserReports = props => {
  //  REDUX
  const hasPointSystem = useSelector(getHasPointSystem)

  const { t } = useTranslation()
  const [value, setValue] = useState(0)

  useEffect(() => {
    // show appreciations report by default when points system is disabled
    !hasPointSystem && setValue(1)
  }, [hasPointSystem])

  const handleChange = (event, newValue) => setValue(newValue)

  const ReportTabs = (
    <Tabs
      indicatorColor='primary'
      textColor='primary'
      variant='fullWidth'
      value={value}
      onChange={handleChange}
    >
      <Tab value={0} wrapped label={t('reportsPage.pointsReport')} />
      <Tab value={1} wrapped label={t('reportsPage.appreciationsReport')} />
    </Tabs>
  )

  const Report =
    value === 0 ? (
      <UserAppreciationsPointsReport />
    ) : (
      <UserAppreciationsAmountReport />
    )

  return (
    <PaddingContainer>
      <Metadata meta={META.reports} />
      <AuthPageTitle>{t('reportsPage.pageTitle')}</AuthPageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={1} />
        <Grid item xs={12} sm={10}>
          <Paper variant='outlined'>
            {hasPointSystem && ReportTabs}
            <ReportContainer>{Report}</ReportContainer>
          </Paper>
        </Grid>
      </Grid>
    </PaddingContainer>
  )
}

export default UserReports

const ReportContainer = styled.div`
  margin-top: 20px;
`
