import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { countryOptions } from 'src/utils/country'
import {
  validateOrganisationDetailsField,
  validateOrganisationDetailsValues
} from 'src/features/organisation/duck/schema'

import { updateOrganisationDetailsRoutine } from 'src/features/organisation/duck/actions'
import {
  getOrganisationDetails,
  isOrganisationLoading
} from 'src/features/organisation/duck/selectors'

import Form from 'src/components/atoms/Form'
import Input from 'src/components/atoms/Input'
import Select from 'src/components/atoms/Select'
import Button from 'src/components/atoms/Button'

const emptyValues = {
  name: '',
  country: ''
}

export const OrganisationDetails = props => {
  //  REDUX
  const dispatch = useDispatch()
  const isLoading = useSelector(isOrganisationLoading)
  const organisation = useSelector(getOrganisationDetails)
  const updateOrganisationDetails = useCallback(
    payload => dispatch(updateOrganisationDetailsRoutine(payload)),
    [dispatch]
  )

  const { t } = useTranslation()
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)
  const [submitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    setInitialValues()
  }, [organisation])

  useEffect(() => {
    isLoading === false && setIsSubmitting(false)
  }, [isLoading])

  useEffect(() => {
    validateOrganisationDetailsValues(values, _validateSchema)
  }, [values])

  const setInitialValues = () =>
    setValues({
      name: organisation.name,
      country: organisation.country
    })

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = async e => {
    e.preventDefault()
    setIsSubmitting(true)
    updateOrganisationDetails(values)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        required
        value={organisation.name}
        name='name'
        label={t('form.organisationName.label')}
        validate={validateOrganisationDetailsField(values)}
        onChange={handleOnChange}
      />
      <Select
        required
        value={organisation.country}
        options={countryOptions()}
        name='country'
        label={t('form.country.label')}
        validate={validateOrganisationDetailsField(values)}
        onChange={handleOnChange}
      />
      <ButtonContainer>
        <Button
          id='submit-organisation-details'
          type='submit'
          disabled={(isLoading && submitting) || !valid}
          onClick={handleSubmit}
          variant='contained'
          color='primary'
        >
          {t('organisationSettingsPage.saveDetailsCTA')}
        </Button>
      </ButtonContainer>
    </Form>
  )
}

export default OrganisationDetails

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
